/* Copyright (C) 2018 HTC Global Services, Inc. - All Rights Reserved  */

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i");
@import url("https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700");
/* @import "~angular-tree-component/dist/angular-tree-component.css"; */
/* @import "~@circlon/angular-tree-component/css/angular-tree-component.css"; */
@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Open Sans", sans-serif !important;
  max-width: 1920px;
  margin: 0 auto !important;
}

body {
  background-color: #d7d9db !important;
  font-family: "Open Sans", sans-serif !important;
}
.primary-text-color {
  color: #61165e !important;
}
/*
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fade-in {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:1s;
    -moz-animation-duration:1s;
    animation-duration:1s;
  }

  .fade-in.content {
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    animation-delay: .1s;
  } */

/* .container {
  width: 90% !important;
  background-color: #ffffff;
  padding: 0 !important;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.3);
} */

/* .container-fluid {
  padding: 0 !important;
  margin-right: auto;
  margin-left: auto;
} */

/* .btn:hover,
.btn:focus,
.btn.focus {
    color: #FFFFFF !important;
} */

.popover-content img.swiper-slide {
  /* width: 100%;
    height: auto; */
  width: 100%;
  height: auto;
  max-height: 300px;
  margin: auto;
  display: block;
  object-fit: contain;
}
.popover-content {
  background-color: #fff;
}
wiley-searchresult-leftmenu-component {
  /* border-right: 1px solid #a7aaad; */
  float: left;
  width: 16%;
}

wiley-searchresult-wrapper-component {
  float: right;
  width: 84%;
}

wiley-footer-component {
  display: inline-block;
  width: 100%;
}

wiley-search-result {
  float: right;
  width: 80% !important;
}

/* Advance Search */

.panel-default .panel-heading.card-header {
  color: #fff;
  cursor: pointer;
  padding: 4px 0 13px 13px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.4s;
  /* font-weight: 700; */
  /* text-transform: uppercase; */
  margin-bottom: 0px;
}

.panel-open .panel-default .panel-heading.card-header {
  /* background: #009CA9; */
  background-color: #006d76;
  color: #fff;
}

.panel-heading {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.panel-default .panel-heading.card-header {
  background: #c1c3c7;
  /* color: #075B80; */
  color: #03364d;
}

.panel-heading h3 {
  padding: 0px 5px;
  font-size: 22px !important;
}

.advancefilter-dropdown .dropdown.dropdown-inline {
  width: 100%;
  display: inline-block;
}

.advancefilter-dropdown .dropdown-inline .btn-secondary {
  width: 100%;
  text-align: left;
  background-color: #efeff1;
  border: none;
  box-shadow: unset;
  border-bottom: 1px solid #70b7b1;
  margin-bottom: 8px;
  height: 35px;
  margin-bottom: 0;
  padding-left: 0;
}

.advancefilter-dropdown .dropdown-inline:focus {
  box-shadow: none !important;
  outline: none;
}

.advancefilter-dropdown .dropdown-inline .btn-secondary:hover,
.dropdown-inline .btn-secondary:focus,
.dropdown-inline .btn-secondary:active,
.dropdown-inline .btn-secondary:visited {
  background: transparent;
  background-color: transparent;
  border-color: none;
  border-bottom: 1px solid #70b7b1;
  box-shadow: none !important;
}

.advancefilter-dropdown ss-multiselect-dropdown.col-md-12.padding-0 {
  padding: 0;
  margin-bottom: 10px;
}

.advancefilter-dropdown .dropdown-toggle span.caret {
  text-align: right;
  float: right;
  margin-top: 9px;
}

.advancefilter-dropdown .dropdown-inline ul.dropdown-menu {
  width: 100%;
}

.open > .advancefilter-dropdown .dropdown-toggle.btn-default {
  color: #333;
  background-color: transparent !important;
  border-color: none;
}

.advancefilter-dropdown .btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #414246 !important;
  background-color: transparent !important;
  border-color: #8c8c8c !important;
  outline: none !important;
}

.advancefilter-dropdown {
  padding-bottom: 10px;
}

.archive-dropdown .btn-secondary {
  padding: 22px 22px 21px;
  font-size: 14px;
  color: #141414;
  font-weight: 700;
  border-radius: 0;
  background: #dddddd;
}

.archive-dropdown .btn-secondary:hover {
  background: #07b2fb;
  color: #fff;
}

.open > .archive-dropdown .dropdown-toggle.btn-default:focus,
.archive-dropdown .dropdown-inline .btn-secondary:active,
.archive-dropdown .dropdown-inline .btn-secondary:focus {
  background: #06b1fa !important;
  color: #fff !important;
}

.selected-list .c-list .c-token {
  margin-right: 5px !important;
}

.advancesearch-archiveDropdowns .selected-list .c-btn {
  width: 100%;
  cursor: pointer;
  display: flex;
  border: none !important;
  border-bottom: 1px solid #059aa6 !important;
  box-shadow: none !important;
}

.advancesearch-archiveDropdowns .dropdown-list {
  padding-top: 0 !important;
}

.advancesearch-archiveDropdowns .arrow-up {
  display: none !important;
}

.add-icon {
  color: #068853;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.delete-icon {
  color: #ea142d;
  cursor: pointer;
}

.no-padding {
  padding: 0 !important;
}

.row.margin-bottom-5 {
  margin: 8px 0;
}

.row.margin-bottom-5 .padding-right-7 {
  padding-left: 0;
}

.list-filter input {
  color: #141414 !important;
}

.archivesearch-Dropdowns .c-btn {
  padding: 22px 22px 21px !important;
  font-size: 14px !important;
  color: #141414;
  font-weight: 700;
  border-radius: 0 !important;
  background: #d7d9db !important;
}

.archivesearch-Dropdowns .fa-angle-down,
.archivesearch-Dropdowns .fa-angle-up {
  margin-top: 8px !important;
  margin-right: -5px;
}

.headerarchivesearch-Dropdowns .cuppa-dropdown {
  width: 400px;
  margin-top: 15px;
}

.headerarchivesearch-Dropdowns .selected-list .c-btn {
  width: 100%;
  box-shadow: none !important;
  padding: 10px;
  cursor: pointer;
  display: flex;
  background: #d7d9db;
  font-weight: 700;
}

.archivesearch-Dropdowns .arrow-up {
  display: none;
}

.archivesearch-Dropdowns .dropdown-list {
  padding-top: 0 !important;
  border-radius: 0 !important;
}

.archivesearch-Dropdowns .list-area {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.archives-radiobtn .pure-checkbox input[type="checkbox"] + label:before {
  border-radius: 24px !important;
}

.archivesearch-Dropdowns .selected-list .c-token {
  background: #d7d9db !important;
  color: #707070 !important;
}

multiselect-dropdown {
  display: block;
  float: left !important;
  /* width: 5% !important; */
}

/* .input-group.archives-menudiv {
  position: relative;
  display: flex;
} */

.archives-menudiv .input-group-btn {
  min-width: 70px;
}

.archives-menudiv .btn.dropdown-toggle.search-toggle {
  width: 100% !important;
}

.overlay-search .archives-search-textbox {
  float: left !important;
  width: 100% !important;
  border: 1px solid #ffffff;
  box-shadow: none;
}
.archives-menudiv .btn-adv-search {
  margin-right: 5px;
  margin-top: 0 !important;
}

.archives-menudiv .dropdown-menu-right {
  right: 5px !important;
  left: auto;
}

.archives-menudiv span.input-group-btn {
  display: flex;
  background-color: #ffffff;
  /*padding: 1px; */
  /* width: 13%; */
}

.archives-menudiv span.input-group-btn button.search-btn {
  /* margin: 1px 0px 0px 0px; */
  display: inline-block;
  /* background-color: #FFFFFF; */
  padding: 1px;
  /* width: 5%; */
}

.archives-menudiv .archive-btnopen {
  display: block !important;
}

.archive-btnopen .dropdown-toggle {
  padding: 14px 0 0 0;
  background: #ddd;
  border: 1px solid #d6d6d6;
}

.option-list {
  padding: 0 !important;
  margin-top: -1px !important;
  margin-left: -1px !important;
  border-radius: 0 !important;
  left: 2px;
}

.btn-adv-search {
  margin-top: 0px !important;
}

.archives-menudiv .btn.search-btn {
  width: 100% !important;
}

/* filter control styles */

.selected-list .c-btn {
  box-shadow: none !important;
}

.dropdown-list .arrow-up {
  display: none !important;
}

.cuppa-dropdown .dropdown-list {
  padding-top: 0 !important;
}

.selected-list .fa-angle-down,
.selected-list .fa-angle-up {
  font-size: 15pt !important;
  right: 5px !important;
  font-weight: 700 !important;
}

/* Common Classes */

.padding-l-0 {
  padding-left: 0 !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-15 {
  padding: 15px;
}

.margin-t-5 {
  margin-top: 15px;
  margin-bottom: 10px;
}

span.fa.fa-remove {
  margin-left: 6px !important;
}

.parent {
  position: relative;
}

.selected-list .c-list .c-token .c-label {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
}

.dropdown-box ul.dropdown-menu-right {
  width: 100%;
  display: block;
  /* max-width: 600px !important; */
  margin-top: -1px !important;
}

.modal-dialog {
  top: 30% !important;
}

/* .tooltip-inner {
  max-width: 287px !important;
  padding: 3px 8px;
  color: #370d36 !important;;
  text-align: left !important;
  background-color: red !important;;
  border-radius: 4px;
} */

.margin-right-15 {
  margin-right: 15px;
}

.cuppa-dropdown:focus {
  outline: 0;
}

angular2-multiselect:focus {
  outline: 0;
}

/* accordian */

.filter-header .panel-heading.card-header {
  background: #fff !important;
  color: #000 !important;
  border: none !important;
  box-shadow: none;
  padding: 5px 0;
}

.filter-header .panel-heading.card-header .panel-title {
  /* font-weight: 300; */
  font-weight: bold;
}

.filter-header.panel-open .panel-heading.card-header .panel-title {
  /* font-weight: 700 !important; */
  font-weight: bold !important;
}

.filter-header.panel-open .panel-heading.card-header {
  color: #009ca8 !important;
}

.filter-options .panel-title {
  font-size: 16px;
  color: #141414 !important;
  font-family: "Source Serif Pro", serif !important;
}

.filter-options .panel-group .panel.card {
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.panel-group .panel {
  margin-bottom: 5px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 0 !important;
}

.filter-options .panel-body.card-block.card-body {
  padding: 0;
  border: 0 !important;
}

.filter-options .accordian-inner {
  padding: 0 !important;
}

.filter-options .checkbox {
  margin: 0;
}

.filter-options .checkbox label {
  font-size: 13px;
  margin-top: 0;
}

.filter-options .checkbox input[type="checkbox"] {
  margin-top: 4px;
}

.filter-options .badge-count {
  font-size: 14px !important;
  font-weight: normal !important;
  /* color: #009CA9 !important; */
  color: #006d76 !important;
}

.filter-options .accordian-inner {
  border: 0 !important;
  margin: 0 !important;
}

.filter-options label.filter-select {
  font-size: 15px;
}

.filter-options .panel-group {
  margin-bottom: 0;
}

.filter-options .accordion-upicn {
  margin-top: 0;
  cursor: pointer;
  margin-right: 10px;
}

/* popover */

.icon-size {
  float: right;
  margin-top: 3px;
}

.popoverUl li {
  width: 100% !important;
  min-height: auto !important;
  font-size: 13px;
  max-width: initial !important;
}

.popoverUl {
  width: 100%;
  padding: 5px;
  padding-bottom: 25px;
}

.csl-entry {
  font-family: "Open Sans", sans-serif !important;
  color: #414246;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.width-15,
wiley-searchresult-content-component .nav.toggle {
  width: 20% !important;
}

wiley-search-result.col-md-10 {
  border-left: 1px solid #a7aaad;
  min-height: 1200px;
  padding: 0 25px;
}

.advanced-searchdiv .dropdown-toggle.btn {
  border: 0;
  border-radius: 0;
  width: 100%;
  text-align: left;
  color: #272829;
  /* background-color: #efeff1; */
  border: none;
  box-shadow: unset;
  border-bottom: 1px solid #61165e;
  margin-bottom: 0;
  height: 41px;
  padding-left: 8px;
  background-image: url(/assets/images/icons/down-arrow.png) !important;
  background-repeat: no-repeat;
  background-position: 98% 14px;
  /* background: url('/assets/images/icons/down-arrow.png') no-repeat 98% 14px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.advanced-searchdiv .dropdown-toggle.btn:hover {
  color: #272829 !important;
}

.advanced-searchdiv .dropdown.dropdown-inline {
  width: 100%;
}

.advanced-searchdiv .caret {
  display: none !important;
}

.advanced-searchdiv .dropdown-menu {
  min-width: 100%;
}

.advanced-searchdiv ul .dropdown-item a span .fa.fa-check {
  width: 20px;
  display: inline-block;
  border: 2px solid #004c97;
  padding: 1px 2px 0;
  height: 19px;
  margin-right: 0;
  color: #004c97;
  position: absolute;
  top: 8px;
  left: 5px;
}

.advanced-searchdiv .dropdown-menu > li > a {
  padding: 3px 10px !important;
  position: relative;
}

.multiselect-dropdown-item-span {
  margin: 3px 0 0 5px;
  display: inline-block;
  white-space: normal;
  position: relative;
  font-size: 13px;
  text-align: left;
  padding-right: 8px;
}

/* image slider */

/* .popover-right {
  top: 17px !important;
} */

popover-container.popover-right.bs-popover-right.right {
  border-radius: 0 !important;
  /* background: #000 !important; */
  width: 200px;
}

.Manuscriptsclass popover-container.popover-left.bs-popover-left.left,
.Photographsclass popover-container.popover-left.bs-popover-left.left,
.Mapsclass popover-container.popover-left.bs-popover-left.left,
.Monographsclass popover-container.popover-left.bs-popover-left.left {
  border-radius: 0 !important;
  background: #000 !important;
  width: 250px;
}

.popover-content img.swiper-slide {
  width: 100%;
  height: auto;
}

.popover.right > .arrow:after {
  border-right-color: #000 !important;
}

/* .searchresult-accordion .panel-default > .panel-heading + .panel-collapse > .panel-body {
    background: #EFEFF1 !important;
} */

.multiselect-dropdown-item ul.dropdown-menu {
  max-width: 330px;
  overflow: hidden;
  padding: 5px;
}

.showfullwidth-searchpage {
  width: 100% !important;
}

search-result-details.col-md-10 {
  width: 80% !important;
}

search-result-details-leftmenu.padding-0,
.padding-0 {
  padding: 0 !important;
}

.docviewer-tabs a {
  float: left;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #61165e;
}

.tab-container ul.nav.nav-tabs {
  border-bottom: 1px solid #61165e;
  padding-bottom: 2px;
}

.nav-tabs > li.active > a {
  color: #ffffff !important;
  border: 0 !important;
}

.nav-tabs > li > a:hover {
  /* background-color: #d8d9da !important; */
  color: #230822;
  margin-top: 0 !important;
}

.nav-tabs > li > a {
  border: 0 !important;
}

.nav > li > a {
  /* background-color: #efeff0; */
  border-radius: 0 !important;
}

/* .nav-link > span:hover {
    color: #230822 !important;
} */

.nav-tabs > li {
  margin-bottom: -2px !important;
}

.nav > li > a {
  /* background-color: #efeff0; */
  border-radius: 0 !important;
}

/* .nav-link > span:hover {
    color: #230822 !important;
} */

.nav-tabs > li {
  margin-bottom: -2px !important;
}

.filter-result .tab-container ul.nav.nav-tabs .nav-link {
  padding: 10px 25px;
  background-color: #efeff0;
  border-radius: 0 !important;
  color: #61165e !important;
}

.filter-result .tab-container ul.nav.nav-tabs .nav-link:hover {
  background-color: #d8d9da;
  color: #230822 !important;
}

.active a.nav-link.active span {
  color: #ffffff;
}

li.nav-item.docviewer-tabs.active {
  background: #61165e !important;
}
li.nav-item.docviewer-tabs.active a {
  background: #61165e !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
  margin-top: 2px !important;
}

li.nav-item.active a {
  background: #61165e !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
}

.nav-item.docviewer-tabs {
  /* border: 1px solid transparent !important; */
  margin-right: 1px !important;
}

.nav-item.tableofcontents-tabs a span {
  font-size: 14px;
}
.nav-item.tableofcontents-tabs a span:focus {
  color: #230822 !important;
}
.nav-item.tableofcontents-tabs a span:active {
  color: #ffffff !important;
}

.nav-item {
  /* border: 1px solid #61165E !important; */
  margin-right: 1px !important;
  margin-right: 1px !important;
  background-color: #efeff0;
}

search-result-details.col-md-10.showfullwidth-searchpage {
  width: 100% !important;
}

.leftmenu-3 {
  float: left;
  width: 3%;
}

.rightmenu-97 {
  float: right;
  width: 97%;
}

.loading-spinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1070;
  background-color: #000;
  opacity: 0.5;
}

search-result-details-wrapper {
  width: 100%;
  display: inline-block;
}

/* .nav_menu .input-group-btn:last-child > .btn{
    margin-right: -1px;
} */

.nav_menu {
  margin-top: 1px;
}
.panel-body.card-block.card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.filter-content .slider-control {
  width: 100% !important;
}

ss-multiselect-dropdown ul.dropdown-menu {
  padding-top: 0;
}

li.dropdown-divider.divider {
  display: none !important;
}

.padding-0 {
  padding: 0 !important;
}

.node-content-wrapper,
.tree-children {
  position: relative;
}

.node-content-wrapper::before,
.tree-children::after {
  content: "";
  position: absolute;
}

.node-content-wrapper::before {
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  height: 28px;
  top: -17px;
  width: 20px;
  left: -28px;
}

.tree-node-level-1
  > tree-node-wrapper
  > .node-wrapper
  > .node-content-wrapper::before {
  display: none;
}

.tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
  width: 25px;
}

.tree-children::after {
  border-left: 1px solid lightgrey;
  height: 100%;
  top: -15px;
  left: -15px;
}

tree-node:last-child > .tree-node > .tree-children::after {
  border-left: none;
}

.toggle-children {
  z-index: 1;
  font-size: 12px;
}

.toggle-children-wrapper-collapsed span.toggle-children:before {
  content: "\f067";
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: -7px;
  color: #a8a8a8;
}

.toggle-children-wrapper-expanded span.toggle-children:after {
  content: "\f068";
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: -7px;
  color: #a8a8a8;
}
.toggle-children {
  background-image: none !important;
}

.toggle-children-wrapper-expanded .toggle-children {
  -webkit-transform: rotate(90deg) !important;
  transform: none !important;
}

tree-node-content {
  color: #908989;
  font-weight: 500;
  font-size: 15px;
}

.node-content-wrapper::before {
  left: -27px !important;
}

.viewList .popover.in.popover-left.bs-popover-left.left {
  max-width: 800px;
  min-width: 800px;
}

.viewlist-content-div .csl-entry,
.viewlist-content-div .csl-entry i {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

li.nav-item.tableofcontents-tabs {
  width: 50% !important;
}

.item-facet-tabset .nav-item {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.item-facet-tabset .nav-item {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

li.nav-item.tableofcontents-tabs.active a {
  background: #61165e !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
  color: #ffffff !important;
}

.tableofcontents-tabs a {
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;
  color: #61165e;
}

.nav-item.tableofcontents-tabs a:focus {
  color: #61165e;
}

.tableofcontents-tabs .treenode-icons {
  width: 100%;
  display: inline-block;
  margin: -10px 0;
}

.top_nav .filter-search-texbox {
  /* font-style: italic; */
  font-size: 16px;
}

::-webkit-input-placeholder {
  color: #767676 !important;
  font-size: 16px !important;
}

::-moz-placeholder {
  color: #767676 !important;
  font-size: 16px !important;
}

:-ms-input-placeholder {
  color: #767676 !important;
  font-size: 16px !important;
}

input::placeholder {
  color: #767676 !important;
  font-size: 16px !important;
}

.details-page-div a.nav-link {
  padding: 3px 1px 3px 6px;
}

.padding-0 {
  padding: 0 !important;
}

.docTitle {
  font-weight: 300;
}

.simple-notification .sn-content {
  font-size: 14px !important;
}

.tableofcontents-tabs tree-viewport,
#monographTree tree-viewport,
#manuscriptTree1 tree-viewport,
#illustrationTree tree-viewport,
#searchresultIllusrations tree-viewport {
  overflow: hidden !important;
}

/* #illustrationTree a {
    max-width: 245px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
} */

.tree-children {
  padding-left: 30px !important;
}

.disclaimer-contents a {
  font-size: 14px !important;
  text-transform: none !important;
}

.toggle-children-placeholder {
  display: block !important;
  height: 0px !important;
}

.tool-dropdownarrow {
  margin-left: 5px;
}

a.image-viewer-highlight {
  opacity: 0.4 !important;
  background-color: #7fff00 !important;
  z-index: 999;
}

a.image-viewer-table {
  opacity: 0.2;
  background-color: rgba(233, 95, 16, 0.637) !important;
  /* z-index: 999; */
  cursor: pointer;
}
@keyframes flickerAnimation {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
a.animate-flicker {
  -webkit-animation: flickerAnimation 1s linear;
  -moz-animation: flickerAnimation 1s linear;
  -o-animation: flickerAnimation 1s linear;
  animation: flickerAnimation 1s linear;
  -webkit-animation-iteration-count: 2; /* Safari 4.0 - 8.0 */
  animation-iteration-count: 3;
}
/* .popover.left {
  margin-left: -5px !important;
} */

.showRelevantPagesDiv {
  visibility: visible;
}

.hideRelevantPagesDiv {
  visibility: hidden;
}

#illustrationTree .node-content-wrapper .title {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 270px;
  text-overflow: ellipsis;
  font-size: 14px !important;
}
#manuscriptTree1 .node-content-wrapper {
  width: 93%;
}
#manuscriptTree1 .toggle-children-wrapper {
  padding: 0;
}
#manuscriptTree2 .title:before {
  content: "\F054";
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: 6px;
  color: #a8a8a8;
}
#manuscriptTree2 .node-content-wrapper {
  width: 97%;
}

#tab2 .node-content-wrapper {
  padding: 2px 14px;
}

/* #illustrationTree .toggle-children-wrapper-collapsed span.toggle-children:before {top: -12px;}
#illustrationTree .toggle-children-wrapper-expanded span.toggle-children:after {top:-12px;} */

@media (min-width: 852px) and (max-width: 1300px) {
  #illustrationTree .node-content-wrapper .title {
    max-width: 155px;
  }
}
body {
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #ffffff;
  max-height: 1000px;
  background: #f7f7f7;
  /* overflow-y: scroll; */
  margin-bottom: 25px;
}

/* body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
} */

body::-webkit-scrollbar {
  width: 10px;
  /* background-color: #f5f5f5;
  height: 6px; */
}

body::-webkit-scrollbar-thumb {
  background-color: #61165e;
  border-radius: 25px;
}

.multiselect-dropdown-item .dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.multiselect-dropdown-item .dropdown-menu::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  height: 6px;
}
.multiselect-dropdown-item .dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #61165e;
  border-radius: 25px;
}

img.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
  width: 20px !important;
  height: 30px !important;
}
img.leaflet-marker-shadow.leaflet-zoom-animated {
  margin-left: -15px !important;
  margin-top: -52px !important;
  display: none !important;
}

.showall-container {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.showall-container input {
  position: absolute;
  opacity: 0;
}

.showall-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #192b67;
  margin-top: 2px;
}
.showall-container input:checked ~ .showall-checkmark {
  background-color: #ffffff;
}

.showall-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.showall-container input:checked ~ .showall-checkmark:after {
  display: block;
}

.showall-container .showall-checkmark:after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* background: #7C7C7C; */
  background-color: #192b67;
}

/* .modal-content {
  width: 478px;
  text-align: center;
} */

.modal-body {
  height: auto;
  min-height: 115px;
  text-align: center;
  font-size: 25px;
}

.sa-icon {
  width: 60px;
  height: 60px;
  border: 4px solid gray;
  border-radius: 50%;
  margin: 20px auto !important;
  position: relative;
  box-sizing: content-box;
}

.sa-icon.sa-warning {
  border-color: #eea236;
}

.sa-icon.sa-warning .sa-body {
  position: absolute;
  width: 5px;
  height: 30px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #f0ad4e;
}

.sa-icon.sa-warning .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #f0ad4e;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-footer {
  border-top: 0 !important;
}

.sa-icon.sa-info {
  border-color: #46b8da;
}

.sa-icon.sa-info .sa-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #46b8da;
}

.sa-icon.sa-info .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #46b8da;
}

.url_body {
  font-size: 15px;
}

.sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
}

.sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #5cb85c;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
}

.sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #5cb85c;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.animateSuccessLong {
  animation: animateSuccessLong 0.75s;
}

.animateSuccessTip {
  animation: animateSuccessTip 0.75s;
}

.sa-icon.sa-success {
  border-color: #4cae4c;
}

.sa-icon.sa-info::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #5bc0de;
}

.sa-icon.sa-info::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #5bc0de;
}
/*
.sa-icon.sa-warning::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 29px;
    left: 50%;
    bottom: 17px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #eea236;
} */

/* .sa-icon.sa-warning::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    top: 19px;
    background-color: #eea236;
} */
#searchresultIllusrations .tree-node {
  text-align: left;
  /* width: 75%; */
  width: 100%;
}

#searchresultIllusrations .node-content-wrapper a {
  font-size: 13px;
}

#searchresultIllusrations
  .toggle-children-wrapper-collapsed
  span.toggle-children:before {
  font-size: 13px;
  top: -7px;
}

#searchresultIllusrations
  .toggle-children-wrapper-expanded
  span.toggle-children:after {
  font-size: 13px;
  top: -7px;
}
#manuscriptTree1 .toggle-children-wrapper-expanded span.toggle-children:after {
  font-size: 13px;
  top: -14px;
}

#manuscriptTree1
  .toggle-children-wrapper-collapsed
  span.toggle-children:before {
  top: -14px;
}

wiley-search-result .floatLeft {
  float: left;
}

wiley-search-result .paddingLeft {
  padding-left: 0;
}

wiley-search-result .searchRecord {
  padding-right: 10px;
}

wiley-search-result .floatRight {
  float: right;
}

wiley-search-result .paddingRight {
  padding-right: 0;
}

wiley-search-result .panel-group .panel + .panel {
  /* padding-top: 20px; */
  margin-top: 0 !important;
}

wiley-search-result .row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

wiley-search-result .panel-group {
  padding-bottom: 0;
  margin-bottom: 0 !important;
}

wiley-search-result ul.nav-tabs {
  padding-top: 5px;
}
wiley-search-result accordion-group {
  padding-bottom: 5px;
}
popover-container {
  margin-top: 0px !important;
}

.pagetitle-popoverdiv a.search-result-header-title {
  font-size: 13px;
  /* color: #fff; */
}

.pagetitle-popoverdiv .csl-entry {
  font-size: 12px;
  /* color: #fff; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img_pad .popover-arrow.arrow {
  display: block;
}
.img_pad .popover.right > .arrow:after {
  border-right-color: #ffffff !important;
}

/* img.accordian-img.center-block.img-responsive {
    height: 95px;
    cursor: pointer;
} */

.aboutIcon {
  background-position: -514px -357px !important;
}

.collectionIcon {
  background-position: -116px -247px !important;
}

.archiveIcon {
  background-position: -39px -406px !important;
}
.imageviewer-menubar .archiveIcon {
  background-position: -155px -175px !important;
}

.toolIcon {
  background-position: -155px -246px !important;
}

.viewlistIcon {
  background-position: -281px -312px !important;
  width: 38px;
  height: 22px;
}

.viewhistoryIcon {
  background-position: -78px -312px !important;
  width: 38px;
  height: 22px;
}

.FeedbackIcon {
  background-position: -118px -412px !important;
  width: 38px;
  height: 22px;
}

.helpIcon {
  background-position: -203px -409px !important;
  width: 38px;
  height: 23px;
}

.endSessionIcon {
  background-position: -162px -411px !important;
}

.toolarrowIcon {
  background-position: -523px -38px !important;
  width: 12px;
  height: 8px;
}

.browsecontentIcon,
.browsecontentIconWpa {
  /* background-position: -237px -251px !important; */
  background-position: -518px -460px !important;
  width: 20px;
  height: 25px;
}

.headingtoolIcon,
.headingtoolIconWpa {
  /* background-position: -195px -250px !important; */
  background-position: -480px -461px !important;
  width: 24px;
  height: 24px;
}

.headingwileyicon {
  background-position: -276px -251px !important;
  width: 24px;
  height: 20px;
}

.headingnyasicon {
  /* background-position: -316px -312px !important; */
  background-position: -441px -461px !important;
  width: 24px;
  height: 20px;
}

.headingraiicon {
  background-position: -435px -311px !important;
  width: 24px;
  height: 20px;
}

/* .frequencyIcon{
    background-position: -514px -86px !important;
	width: 27px;
	height: 25px;
} */

.addbookmarkIcon {
  background-position: -356px -250px !important;
  width: 23px;
  height: 23px;
}

.spriteico {
  float: left;
  background: url(/assets/images/icons/sprite_img-2.png) no-repeat;
  display: block;
  width: 28px;
  height: 28px;
}

.panel-body.card-block.card-body {
  padding-top: 0;
}

.popover-arrow arrow {
  display: block !important;
}

.flipster--carousel .flipster__item--current .flipster__item__content {
  text-align: center;
}

.flipster {
  overflow-y: hidden !important;
}

ul.flipster__nav {
  display: none !important;
}

.modal-content {
  width: 100% !important;
  margin-top: -150px;
  text-align: center !important;
}

img.bm-icon.shareIcon {
  height: 500px;
}

.img_pad .popover-right .popover-arrow.arrow {
  display: none;
}

.illustrationsIcon {
  background-position: -204px -460px !important;
  width: 19px;
  height: 23px;
}

.addtolistIcon {
  background-position: -242px -311px !important;
  width: 18px;
  height: 26px;
}

.removefromlistIcon {
  background-position: -242px -410px !important;
  width: 25px;
  height: 23px;
}

.bookmarkcopiedIcon {
  background-position: -284px -411px !important;
  width: 25px;
  height: 23px;
}

.serch-icns .addbookmarkIcon {
  background-position: -360px -250px !important;
  width: 17px;
  height: 26px;
}

.illustrationsIcon.img-disable {
  background-position: -119px -367px !important;
  width: 20px;
  height: 26px;
}
/* .panel-body.card-block.card-body {
    padding: 0 10px 0 0;
} */
wiley-search-result .panel-open .heading {
  color: #ffffff;
}
wiley-search-result .heading {
  position: relative;
  top: 4px;
  color: #005274;
}

.panel-heading {
  padding: 15px 15px;
}

/* .searchresult-imgdiv .tooltip.bottom {
    padding: 5px 0;
    margin-top: 0px;
    width: 120px;
    left: 0 !important;
} */

.searchresult-imgdiv .tooltip.bottom .tooltip-arrow {
  left: 92% !important;
  border-bottom-color: #ffffff !important;
}

/* .searchresult-imgdiv .tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #000;
    text-align: center;
    background-color: #fff;
    box-shadow: 1px 2px 1px 1px #888888;
    border-radius: 0;
} */

/* wiley-search-result .csl-entry i {
    font-style: normal;
} */

.gridViewSwitch .view-icn .tooltip.bottom {
  margin-top: 40px !important;
}

.gridBookmark .tooltip.bottom,
.gridlist .tooltip.bottom,
.gridviewIllustration .tooltip.bottom {
  padding: 4px 0 !important;
}

.gridViewSwitch .gridBookmark .tooltip.bottom .tooltip-arrow,
.gridViewSwitch .gridlist .tooltip.bottom .tooltip-arrow,
.gridViewSwitch .gridviewIllustration .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #ffffff !important;
  left: 50% !important;
  margin-left: -14px !important;
  border-width: 0 5px 5px !important;
}

.gridViewSwitch .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #ffffff !important;
  left: 97% !important;
  margin-left: -14px !important;
  border-width: 0 5px 5px !important;
}
.gridViewSwitch .tooltipShow .tooltip.in,
.gridViewSwitch .img_pad .tooltip.in {
  opacity: 1;
  left: 22px !important;
  width: 236px;
}
.listViewSwitch .tooltipShow .tooltip.in,
.listViewSwitch .img_pad .tooltip.in {
  opacity: 1;
  left: 0 !important;
  width: 236px;
}
.tooltipShow .tooltip-inner,
.img_pad .tooltip-inner {
  width: 100%;
  padding: 13px 20px;
  color: #370d36 !important;
  text-align: left;
  background: #fff !important;
  border-radius: 4px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.tooltipShow .tooltip-arrow,
.img_pad .tooltip-arrow {
  border-top-color: #ffffff !important;
  /* left: 47% !important; */
}

/* .PhotographsGridIcon ul.search-iconsdiv li {
    padding: 10px 38px 16px 20px !important;
    width: 49.5% !important;
} */
/* .gridviewDiv.Photographsview .photographwidth.positions li.position{
  width: 32.4% !important;
}
.gridviewDiv .Photographsshow .photographwidth.positions li{
  padding: 10px 36px 16px 20px !important;
  width: 32.4% !important;
} */
.MapsGridIcon ul.search-iconsdiv li {
  width: 49.3% !important;
  /* width: 49.5% !important; */
}
.tooltip-inner {
  width: 100%;
  padding: 5px 5px;
  color: #370d36 !important;
  text-align: left;
  background: #fff !important;
  border-radius: 4px;
  border: 1px solid #989696;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.tooltip.left .tooltip-arrow {
  border-left-color: #ffffff !important;
}

.tooltip.left {
  padding: 0px 5px;
  margin-left: -3px;
  margin-top: -8px;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #ffffff !important;
  bottom: 0 !important;
  /* left: 50% !important; */
  border-width: 6px 6px 0 !important;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #ffffff !important;
}
.gridViewSwitch .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #ffffff !important;
  left: 97% !important;
  margin-left: -14px !important;
  border-width: 0 5px 5px !important;
}

.listViewSwitch .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #ffffff !important;
  left: 41%;
  margin-left: -14px !important;
  border-width: 0 5px 5px !important;
}
.pub-dateDiv .tooltip.top {
  /* margin-top: -18px; */
  pointer-events: none;
}
.tooltip.bottom {
  padding: 4px 0 !important;
  /* margin-top: 100px !important; */
}

.tooltip.in {
  opacity: 1 !important;
}

.slider {
  width: 50%;
  margin: 100px auto;
}

.slick-slide {
  margin: 0px 20px;
}

.slick-prev:before,
.slick-next:before {
  color: #141414;
}

.slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.2;
}

.slick-active {
  opacity: 0.5;
}

.slick-current {
  opacity: 1;
}

.ui-coverflow-wrapper {
  height: 400px;
  margin-top: 100px;
}

.ui-state-active {
  border: 0px;
}

.slick-prev:before,
.slick-next:before {
  font-family: FontAwesome !important;
  font-size: 30px !important;
  opacity: 0.7 !important;
  color: #ffffff !important;
  position: absolute;
}

.slick-prev:before {
  content: "\F053" !important;
  right: -13px;
}

.slick-next:before {
  content: "\F054" !important;
  left: -25px;
}

.viewlist-listcontainer .popover.in.popover-left.bs-popover-left.left {
  top: 0px !important;
  border-radius: 0;
}

.viewlist-listcontainer .popover.left {
  margin-left: 1px !important;
}

.history_popover .popover.in.popover-left.bs-popover-left.left {
  /* top: auto !important; */
  border-radius: 0;
}

.viewlist-container .popover-arrow.arrow,
.popover-arrow.arrow {
  display: none !important;
}

.wiley-secondary-text-color {
  /* color: #14838d !important; */
  color: #61165e !important;
}

.wiley-secondary-background-color {
  background-color: #009ca9;
}
.wiley-primary-text-color {
  color: #61165e !important;
}

img.slick-slide {
  border: 5px solid #000;
}

.history_popover .popover.in.popover-left.bs-popover-left.left {
  max-width: 1000px;
}

.modal-body.url_body.cover_flow {
  background: #383333;
  border: 2px solid #ffffff;
}

label.document-titlelbl {
  color: #fff;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
  cursor: pointer;
}

.historypop .popover.in.popover-left.bs-popover-left.left,
.historyActive .popover.in.popover-left.bs-popover-left.left {
  min-width: 960px;
  /* width: 80%; */
  /* top: 0 !important; */
  cursor: default;
}

.form-control.history-select {
  display: inline-block;
  width: 92%;
}

.form-control {
  border-radius: 0px !important;
}
.historyBatch .search-resultlbl {
  width: 100%;
}

.popover {
  font-family: "Open Sans", sans-serif !important;
}

.archivesCollectionDetails .panel-body {
  padding: 10px 0 10px 0 !important;
}

#tab2 {
  padding-top: 19px;
}

.large-modal-coverflow {
  height: 500px !important;
  width: 70% !important;
}

.slick-track {
  display: inline-flex !important;
}

.slick-slide img {
  display: block;
  height: 500px;
}

.coverflow-thumb-outer-div,
.slick-slide {
  width: 400px !important;
  height: 560px !important;
  background-color: #141414;
  padding: 5px 10px 10px;
}

.slick-center .tooltip-inner {
  margin-top: 65px !important;
}

.slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.5 !important;
}

.slick-current.slick-center {
  opacity: 1 !important;
}

.slick-slide .tooltip-arrow {
  display: none;
}

.slick-slide img {
  cursor: pointer;
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
  max-height: 500px !important;
}
/* .mCustomScrollBox.mCS-minimal-dark.mCSB_vertical.mCSB_outside{
    padding-top: 10px;
} */
.searchQuery popover-container.popover {
  border: 1px solid #414246;
  /* top: 67px; */
  /* left: 215px; */
  border-radius: 0px;
}

.coverFLowDocTitle {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.searchQry .popover.bottom,
.full_text .popover.bottom {
  margin-top: 0px;
  /* left: auto !important; */
  border-radius: 0px;
}

/* .searchQry .popover-content {
} */
.full_text .popover-content {
  padding: 5px;
}
.searchQry .popover-content {
  max-height: 200px;
  overflow-y: auto;
  width: 200px;
  min-width: 180px;
  padding: 10px;
}
.full_text .popover-content {
  max-height: 300px;
  overflow-y: auto;
  width: 151px;
  min-width: 151px;
}

.full_text .popoverUl {
  padding-bottom: 0;
}

.searchQry .popover-content::-webkit-scrollbar-track,
.full_text .popover-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.searchQry .popover-content::-webkit-scrollbar,
.full_text .popover-content::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.searchQry .popover-content::-webkit-scrollbar-thumb,
.full_text .popover-content::-webkit-scrollbar-thumb {
  background-color: #61165e;
  border-radius: 25px;
}
.slick-next.hideslickArrow {
  display: none !important;
}

.slick-prev.hideslickArrow {
  display: none !important;
}

.set-full-width {
  width: 100% !important;
  position: absolute;
  /* top: -57px; */
  height: auto !important;
}

.btn,
input {
  z-index: 1;
}

.tooltip {
  z-index: 3;
}

.popover {
  z-index: 2;
}

.viewlist-listcontainer
  .noHistory
  .popover.in.popover-left.bs-popover-left.left {
  top: 0px !important;
}

.searchBox {
  margin: 15px 0;
}

.navbar-header.tooltip {
  z-index: 5;
}

.term-frequency .tab-content {
  min-height: 420px;
}
.feedback .popover {
  min-width: 660px;
}

.btn {
  border-radius: 0px !important;
}

.details-page-div .panel-body.card-block.card-body {
  padding: 0px 0px 5px 0px !important;
}

.toggle-filterdiv {
  float: left;
  width: 20%;
}

.toggle-filterdivleft {
  float: left !important;
  width: 86% !important;
}

.toggle-filterdivleft label {
  background: #61165e;
  color: #ffffff;
  padding: 15px 25px 11px 16px;
  margin-bottom: 0;
  width: 100%;
  border-right: 1px solid #ffffff;
}

.toggle-filterright {
  float: right !important;
  width: 14% !important;
}

.toggle-filterright a {
  background-color: #61165e;
  color: #ffffff;
  padding: 15px 23px 31px 20px;
  margin-bottom: 0;
  width: 100%;
  display: block;
  cursor: pointer;
  padding-left: 37%;
}

.toggle-filterright a:hover {
  color: #ffffff !important;
}

.toggle-filterrightdiv {
  width: 3%;
  float: left;
}

.toggle-filterrightdiv a {
  background-color: #61165e;
  color: #ffffff;
  padding: 15px 23px 31px 20px;
  margin-bottom: 0;
  width: 100%;
  display: block;
  cursor: pointer;
  padding-left: 37%;
}

.toggle-filterrightdiv a:hover {
  color: #ffffff !important;
}

.float-right {
  float: right !important;
}

.term-frequency_slider .slider-control {
  width: 100% !important;
}

.pageheader {
  font-weight: bold;
  color: #141414;
  margin: 20px 0 5px 0;
  font-size: 22px;
  font-family: "Source Serif Pro", serif !important;
}

.small-underline {
  position: relative;
  /* margin-bottom: 10px; */
}
.small-underline:after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 1.2em;
  border-bottom: 1px solid;
}
.arabic-style.small-underline:after {
  right: 0;
  left: auto;
}

/*
* New Button style
*/
.btn-primary {
  color: #ffffff !important;
  background-color: #61165e !important;
  border-color: #61165e !important;
  outline-color: #61165e !important;
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff !important;
  background-color: #370d36;
  border-color: #2d0a2c;
  box-shadow: 0 0 0 0.2rem rgba(97, 22, 94, 0.5);
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 22, 94, 0.5);
}

.btn-primary:hover,
.btn-primary:focus {
  color: #ffffff !important;
  background-color: #420f40 !important;
  border-color: #370d36 !important;
}

/* .btn-primary:active, .btn-primary:focus{
    background-color: #61165E !important;
    border-color: #61165E !important;
} */
/* .btn-primary:hover{
    text-decoration: underline !important;
} */

.btn:focus {
  outline: none !important;
}

.btn-secondary {
  color: #ffffff;
  background-color: #414246;
  border-color: #414246;
}

.btn-secondary:hover,
.btn-secondary:focus {
  color: #ffffff !important;
  background-color: #2f2f32 !important;
  border-color: #28292c !important;
}

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff !important;
  background-color: #28292c !important;
  border-color: #222325 !important;
  box-shadow: 0 0 0 0.2rem rgba(65, 66, 70, 0.5) !important;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 66, 70, 0.5) !important;
}

.page-heading {
  font-weight: bold;
  color: #141414;
  margin: 5px 0 5px 0;
  font-size: 22px;
  font-family: "Source Serif Pro", serif !important;
}

.borderLeftMenu {
  border-right: 1px solid #c2c3c6;
  /* box-shadow: 1px 2px 1px #C2C3C6; */
  /* -webkit-box-shadow: 10px 10px 37px -9px rgba(0,0,0,0.59);
    -moz-box-shadow: 10px 10px 37px -9px rgba(0,0,0,0.59);
    box-shadow: 10px 10px 37px -9px rgba(0,0,0,0.59); */
}

search-result-details {
  border-left: 1px solid #c2c3c6;
  min-height: 1000px !important;
}

.leftToggleIcon {
  background-position: -324px -462px !important;
  cursor: pointer;
}

.rightToggleIcon {
  background-position: -364px -462px !important;
  cursor: pointer;
}

.arrowDownIcon {
  background-position: -236px -456px !important;
  cursor: pointer;
}

.arrowUpIcon {
  background-position: -277px -456px !important;
  cursor: pointer;
}
.citation_type_header .tooltip.bottom .tooltip-arrow {
  left: 50% !important;
  margin-left: -5px !important;
}

.footer__logo {
  background-repeat: no-repeat;
  /* width: auto; */
  height: 25px;
  right: 30px;
  width: auto !important;
  min-width: 120px !important;
}
.pub-dateDiv {
  width: 100%;
  position: relative;
}
.pub-dateDiv div.dropdown-backdrop {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

/* .form-control:focus {
    color: #2f3032 !important;
    background-color: #FFFFFF !important;
    border-color: #c92ec3 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(97,22,94,.25) !important;
  } */
label.radio-inline.showall-container.active-filter {
  color: #192b67 !important;
}

.noCount.historyActive .popover.in.popover-left.bs-popover-left.left,
.noHistory.historyActive .popover.in.popover-left.bs-popover-left.left {
  min-width: auto;
}
.modal-open {
  overflow: auto !important;
  padding-right: 0 !important;
}
.listViewSwitch .popover-content img.swiper-slide,
.gridViewSwitch .popover-content img.swiper-slide {
  width: 100%;
  /* height: 280px; */
}
.modal-dialog.large {
  width: 45%;
}
.illustration-title {
  font-size: 20px;
  padding-top: 20px;
}
.citationBody {
  padding-top: 0 !important;
}
.citationInnerHtml .cslEntry {
  /* padding-left: 20px; */
  /* width: 740px; */
  width:100%;
  margin-bottom: 0 !important;
  font-weight: 500;
}
.citationInnerHtml .csl-entry {
  font-weight: 300 !important;
}
/*
a {
    color: #61165e;
    text-decoration: none !important;
    font-weight: 600 !important;
    background-color: transparent !important;
} */

/* a.visited, a.wiley-primary-text-color.visited {
    color: #414246;
} */

a.wiley-primary-text-color:hover {
  color: #230822 !important;
  text-decoration: underline !important;
}
.node-content-wrapper-focused {
  background: #d4d6d8 !important;
  /* padding: 4px 15px !important; */
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .archives-menudiv .input-group-btn {
    min-width: 60px;
  }
  .historypop .popover.in.popover-left.bs-popover-left.left,
  .historyActive .popover.in.popover-left.bs-popover-left.left {
    /* min-width: 450px !important; */
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .citationInnerHtml .csl-entry {
    width: 88% !important;
  }
  .modal-dialog.large {
    width: 79% !important;
  }

  .modal-dialog.illustration-popup {
    width: 80% !important;
  }
  .archives-menudiv .input-group-btn {
    min-width: 60px;
  }
  .csl-entry {
    font-size: 14px !important;
    padding-right: 20px !important;
  }
  search-result-details {
    border-left: none;
  }
  .mobileFilmStripDropdown .mCSB_container {
    overflow: auto !important;
  }
  .mCSB_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  .mCSB_container::-webkit-scrollbar-track {
    width: 6px;
    background-color: #f5f5f5;
  }
  .mCSB_container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.51);
    border-radius: 25px;
  }
  .mCSB_container::-webkit-scrollbar {
    height: 6px;
  }
  .add-icon {
    padding-right: 6px;
  }
  wiley-search-result .row {
    margin-bottom: 7px;
  }
  /*
    .dropdown-box ul.dropdown-menu-right {
        width: 500px !important;
    } */
  .toggle-filterdiv {
    width: 45% !important;
    position: absolute;
    z-index: 99999;
  }
  .heatmap-result-leftbar .toggle-filterdiv {
    width: 100% !important;
  }
  .toggle-filterright {
    width: 12% !important;
  }
  .toggle-filterdivleft {
    width: 88% !important;
  }
  .heatmap-result-leftbar .toggle-filterdivleft {
    width: 88% !important;
  }
  /* .openAbout .toggle-filterdivleft {
        width: 86% !important;
    } */
  .width-15,
  wiley-searchresult-content-component .nav.toggle {
    width: 45% !important;
    position: absolute !important;
    z-index: 9999;
    background-color: #ffffff;
    top: 13%;
    overflow-y: auto;
    max-height: 850px;
  }
  wiley-search-result,
  .width-80 {
    width: 100% !important;
  }
  .details-page-div {
    position: absolute !important;
    z-index: 9999;
    background-color: rgb(255, 255, 255);
    top: 24%;
    width: 45% !important;
  }
  /* .width-15::after, wiley-searchresult-content-component .nav.toggle::after,
    .details-page-div::after {
        float: left;
        width: 100%;
        height: 20px;
        content: '';
        background: #fff;
        position: sticky;
        bottom: -2px;
        left: 0;
        z-index: 9999;
    } */
  .historypop .popover.in.popover-left.bs-popover-left.left,
  .historyActive .popover.in.popover-left.bs-popover-left.left {
    min-width: 420px !important;
    padding: 10px 7px;
    max-width: 420px !important;
    /* left: -500px; */
  }
  /* .popover-content {
    padding: 0 !important;
} */
  .viewList .popover.in.popover-left.bs-popover-left.left {
    min-width: 500px !important;
    max-width: 500px !important;
    /* left: -500px !important; */
  }
  .details-page-searchcontent {
    padding-left: 15px;
  }
  .heatmap-result-leftbar .toggle-filterrightdiv {
    float: none !important;
  }
  #searchResultDiv.heatmap-result-leftbar .listViewSwitch .panel-heading {
    padding: 15px 0px !important;
  }
  #searchResultDiv.heatmap-result-leftbar
    .listViewSwitch
    .gridview-panel
    .mobileContentSubType
    .dropdown-menu {
    width: 164px;
    padding: 10px;
    margin-left: 15px;
    margin-top: -15px;
    border-radius: 0;
    min-height: 0;
    max-height: 500px;
    overflow-x: auto;
  }
  #searchResultDiv.heatmap-result-leftbar
    .listViewSwitch
    .gridview-panel
    .mobileContentSubType
    .dropdown-menu::-webkit-scrollbar {
    height: 6px;
  }
  #searchResultDiv.heatmap-result-leftbar .listViewSwitch .contentsubType {
    width: 165px;
  }
  .dropdown-menu.shareWidth {
    min-width: 287px !important;
    padding: 4px !important;
    left: -81px;
  }
  .dropdown-menu.shareWidth .mailIcon {
    width: 38px;
    font-size: 1.2em;
    min-width: 2em;
    padding: 2px 5px 3px 5px;
    border-radius: 4px;
    /* margin: .3125em; */
  }
  .dropdown-menu.shareWidth .sb-wrapper {
    min-width: 3.125em;
    height: 2em;
  }
  /* .modal-content {
    margin-top: -150px;
    /* left: -42%; */
  /* }  */
  .adv_search {
    max-height: 172px !important;
    overflow-x: hidden;
    /* overflow-y: auto; */
  }
  a:hover,
  a:focus {
    color: #23527c;
    text-decoration: none !important;
  }
  #searchResultDiv.heatmap-result-leftbar
    .listViewSwitch
    .gridview-panel
    .mobileContentSubType
    .dropdown-menu:-webkit-scrollbar {
    height: 6px;
  }
  .archive-content {
    margin-top: 15px;
  }
  .details-page-div.detailPageHeader {
    top: 19%;
  }
  .details-page-div.nodetailPageHeader {
    top: 14%;
  }
  .borderLeftMenu.searchPageHeader {
    top: 19%;
  }
  .borderLeftMenu.nosearchPageHeader {
    /* top: 14%; */
    top: 21%;
  }
  .listViewSwitch .Mapsshow .gridview-panel .panel-heading.panel-block {
    padding: 15px 20px !important;
  }
  .nosearch .gridviewDiv ul.search-iconsdiv li.position {
    width: 22.7% !important;
  }
  .heatmap-result-leftbar
    .nosearch
    .gridviewDiv
    ul.search-iconsdiv
    li.position {
    width: 24% !important;
    margin-left: 0.025%;
  }
  .nosearch .gridviewDiv ul.search-iconsdiv li.position {
    width: 23% !important;
  }
  /*#viewlist-content{
  width: 196px !important;
}*/
  .historyDate {
    width: 130px !important;
  }
  .viewlist-container-thumbnail {
    width: 73px !important;
  }
  .date-pickeron input.date-picker {
    padding: 5px 4px !important;
  }
  .heatmap-result-leftbar
    .gridViewSwitch
    popover-container.popover-right.bs-popover-right.right {
    width: 150px;
  }
  .heatmap-result-leftbar
    .listViewSwitch
    .Photographsclass
    popover-container.popover-right.bs-popover-right.right {
    width: 163px;
  }
  /* .defaultView
    div.gridViewSwitch:nth-child(5n)
    popover-container.popover-right.bs-popover-right.right {
    left: -77px !important;
  } */
  popover-container.popover-right.bs-popover-right.right {
    width: 150px;
  }
  .history_table .pointer .fa {
    padding-left: 3px;
  }
  .modal-dialog.termfrequency-popup {
    width: 600px !important;
  }
  .btn-custom-print {
    margin-top: 0;
    margin-right: 0;
  }
  /* .search-resultcount{
  right: 33% !important;
}
.badge .close_content {
  right: 31% !important;
} */
  .viewlist-listcontainer .popover.in.popover-left.bs-popover-left.left {
    min-width: 420px !important;
    max-width: 420px !important;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .citationInnerHtml .csl-entry {
    width: 93% !important;
  }
  .modal-dialog.large {
    width: 59% !important;
  }
  .archives-menudiv .input-group-btn {
    min-width: 60px;
  }
  .csl-entry {
    font-size: 14px !important;
  }
  search-result-details {
    border-left: none;
  }
  wiley-search-result .row {
    margin-bottom: 7px;
  }
  .mobileFilmStripDropdown .mCSB_container {
    overflow: auto !important;
  }
  .mCSB_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  .mCSB_container::-webkit-scrollbar-track {
    width: 6px;
    background-color: #f5f5f5;
  }
  .mCSB_container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.51);
    border-radius: 25px;
  }
  .toggle-filterdiv {
    width: 45% !important;
    position: absolute;
    z-index: 99999;
  }
  .heatmap-result-leftbar .toggle-filterdiv {
    width: 100% !important;
  }
  .width-15,
  wiley-searchresult-content-component .nav.toggle {
    width: 45% !important;
    position: absolute !important;
    z-index: 9999;
    background-color: #ffffff;
    /* top: 13%; */
    overflow-y: auto;
    max-height: 575px;
  }
  wiley-search-result,
  .width-80 {
    width: 100% !important;
  }
  .details-page-div {
    position: absolute !important;
    z-index: 9999;
    background-color: rgb(255, 255, 255);
    top: 14%;
    width: 45% !important;
  }
  /* .width-15::after, wiley-searchresult-content-component .nav.toggle::after,
    .details-page-div::after {
        float: left;
        width: 100%;
        height: 20px;
        content: '';
        background: #fff;
        position: sticky;
        bottom: -2px;
        left: 0;
        z-index: 9999;
    } */
  .historypop .popover.in.popover-left.bs-popover-left.left,
  .historyActive .popover.in.popover-left.bs-popover-left.left {
    min-width: 600px !important;
    padding: 10px 7px;
    max-width: 600px !important;
    /* left: -599px; */
  }

  /* .popover-content {
    padding: 0 !important;
} */
  .viewList .popover.in.popover-left.bs-popover-left.left {
    max-width: 600px !important;
    min-width: 600px !important;
  }
  .details-page-searchcontent {
    margin-top: 55px;
    padding-left: 7px;
  }
  #searchResultDiv.heatmap-result-leftbar .listViewSwitch .panel-heading {
    padding: 15px 9px !important;
  }
  #searchResultDiv.heatmap-result-leftbar .listViewSwitch .contentsubType {
    width: 200px;
  }
  #searchResultDiv.heatmap-result-leftbar
    .listViewSwitch
    .gridview-panel
    .mobileContentSubType
    .dropdown-menu {
    width: 200px;
    padding: 10px;
    margin-left: 9px;
    margin-top: -15px;
    border-radius: 0;
    min-height: 0;
    max-height: 500px;
    overflow-x: auto;
  }
  #searchResultDiv.heatmap-result-leftbar
    .listViewSwitch
    .gridview-panel
    .mobileContentSubType
    .dropdown-menu:-webkit-scrollbar {
    height: 6px;
  }

  .dropdown-menu.shareWidth {
    min-width: 287px !important;
    padding: 4px !important;
    left: -81px;
  }
  .dropdown-menu.shareWidth .mailIcon {
    width: 38px;
    font-size: 1.2em;
    min-width: 2em;
    padding: 2px 5px 3px 5px;
    border-radius: 4px;
    /* margin: .3125em; */
  }
  .dropdown-menu.shareWidth .sb-wrapper {
    min-width: 3.125em;
    height: 2em;
  }
  /* .modal-content {
    margin-top: -150px;
    /* left: -42%; */
  /* }  */
  .adv_search {
    height: 172px !important;
    overflow-x: hidden;
    /* overflow-y: auto; */
  }
  a:hover,
  a:focus {
    color: #23527c;
    text-decoration: none !important;
  }
  #searchResultDiv.heatmap-result-leftbar
    .listViewSwitch
    .gridview-panel
    .mobileContentSubType
    .dropdown-menu::-webkit-scrollbar {
    height: 6px;
  }
  .archive-content {
    margin-top: 15px;
  }
  .details-page-div.detailPageHeader {
    top: 22%;
  }
  .details-page-div.nodetailPageHeader {
    top: 14%;
  }
  .borderLeftMenu.searchPageHeader {
    top: 27%;
  }
  .borderLeftMenu.nosearchPageHeader {
    /* top: 19%; */
    top: 15%;
  }
  .nosearch .gridviewDiv ul.search-iconsdiv li.position {
    width: 23% !important;
  }
  .heatmap-result-leftbar
    .nosearch
    .gridviewDiv
    ul.search-iconsdiv
    li.position {
    width: 24% !important;
    margin-left: 0.025%;
  }
  /*#viewlist-content{
  width: 210px !important;
}*/
  .historyDate {
    width: 130px !important;
  }
  .viewlist-container-thumbnail {
    width: 89px !important;
  }
  .heatmap-result-leftbar
    .gridViewSwitch
    popover-container.popover-right.bs-popover-right.right {
    width: 161px;
  }
  /* .defaultView
    div.gridViewSwitch:nth-child(5n)
    popover-container.popover-right.bs-popover-right.right {
    left: -82px !important;
  } */
  popover-container.popover-right.bs-popover-right.right {
    width: 195px;
  }
  .history_table .pointer .fa {
    padding-left: 3px;
  }
}

.toggleHighlightOn a.image-viewer-table {
  background-color: transparent !important;
}
.toggleHighlightOn a.image-viewer-highlight {
  background-color: transparent !important;
}
.leaflet-control-attribution {
  display: none;
}
.illustration-customclass {
  border-radius: 0 !important;
  background: #000 !important;
  width: 200px;
  height: 200px;
  left: 0 !important;
}
.illustration-image-body {
  height: 190px;
  width: 100%;
}
/* .illustration-image-body img.accordian-img.center-block.img-responsive {
    width: 100% !important;
} */
.modal-dialog.illustration-popup {
  width: 52%;
}
.image-photo-highlight,
.image-keyword-highlight {
  background: #fff000;
  opacity: 0.3;
  cursor: default !important;
}
.image-headword-highlight {
  background: #ad3387;
  opacity: 0.3;
  cursor: pointer !important;
}
.image-map-highlight {
  background: #fff000;
  opacity: 0.3;
  cursor: default !important;
}
.toggleHighlightOn .image-photo-highlight,
.toggleHighlightOn .image-map-highlight {
  background: transparent !important;
}
/* #visualization {
  width: 100vw;
  height: 600px;
} */

/* a, a:visited {
    color: red !important;
} */
#illustrationTree .illustration-tree-scroll {
  min-height: 60vh;
  max-height: 63vh;
  /* overflow: auto; */
}
#itemTab1 .manuscriptTree2-tree-scroll,
#itemTab2 .manuscriptTree2-tree-scroll {
  height: 61vh;
}
#searchresultIllusrations .angular-tree-component {
  height: 300px;
  overflow: auto;
}
.toc-tree-scroll {
  min-height: 64vh;
  max-height: 64vh;
  /* overflow: auto; */
}

#itemTab1::-webkit-scrollbar-track,
#itemTab2::-webkit-scrollbar-track,
.illustrations-treenode .angular-tree-component::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #F5F5F5; */
}

#itemTab1::-webkit-scrollbar,
#itemTab2::-webkit-scrollbar
  .illustrations-treenode
  .angular-tree-component::-webkit-scrollbar {
  width: 6px;
  /* background-color: #F5F5F5; */
}
#itemTab1::-webkit-scrollbar-thumb,
#itemTab2::-webkit-scrollbar-thumb,
.illustrations-treenode .angular-tree-component::-webkit-scrollbar-thumb {
  background-color: #61165e;
  border-radius: 25px;
}
.term-frequency .dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  width: 100% !important;
}
.illustration-modal .tree-children {
  padding-left: 30px !important;
  max-width: 660px;
}

.MapsGridIcon .MapsListViewIcon.search-iconsdiv li {
  width: auto !important;
}
.pad-11 {
  padding: 7px !important;
}
.image-editor-film-strip.hide-filmstrip,
.mCSB_dragger_bar.hide-thumbnail,
.mCustomScrollBox.hide-thumbnail {
  visibility: hidden;
}
@media only screen and (min-width: 1025px) and (max-width: 1340px) {
  .viewList .popover.in.popover-left.bs-popover-left.left {
    max-width: 700px !important;
    min-width: 700px !important;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    max-width: 100% !important;
  }
  .sendMail .form-group label {
    display: block;
  }
  .dropdown-box ul.dropdown-menu-right {
    overflow: auto;
    overflow-x: hidden !important;
  }
  .modal-popup-parentDiv .emailSend div button {
    margin: 0 25px 0 0;
  }
  .historypop .popover.in.popover-left.bs-popover-left.left,
  .historyActive .popover.in.popover-left.bs-popover-left.left,
  .viewlist-listcontainer .popover.in.popover-left.bs-popover-left.left,
  .helpguide popover-container.popover.in.popover-left.bs-popover-left.left {
    padding-bottom: 10px;
    position: relative;
    width: 100% !important;
    min-width: 100% !important;
    margin: 0 !important;
    /* left: 0 !important; */
    float: left;
    cursor: default;
    /* top: -8px !important; */
  }

  .add-icon,
  .delete-icon {
    font-size: 1.7em !important;
  }
  label.blink-wordlbl:empty {
    display: none;
  }
  .toggle-filterdiv {
    width: 51% !important;
    position: absolute;
    z-index: 9999;
  }
  search-result-details-leftmenu .toggle-filterdiv {
    width: 68% !important;
  }
  .width-15,
  wiley-searchresult-content-component .nav.toggle {
    /* width: 51% !important; */
    width: 100% !important;
    position: absolute !important;
    background: #fff;
    top: 159px;
    z-index: 9999;
  }
  .heatmap-result-leftbar .toggle-filterdiv {
    width: 100% !important;
  }
  .csl-entry {
    font-size: 12px;
    line-height: normal;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
  }
  wiley-search-result .floatRight {
    margin-bottom: 5px;
  }
  /* .MapsGridIcon .MapsListViewIcon.search-iconsdiv li {
    width: 20px !important;
    padding: 0 !important;
  } */
  .nav_menu {
    margin-top: 0;
  }
  /* .popover-content > .col-md-12 {
        padding: 0;
    } */
  /* .PhotographsGridIcon ul.search-iconsdiv li {
        padding: 5px 25px 5px 10px !important;
    } */
  .toggle-filterrightdiv {
    width: 5%;
  }
  /* .filter-search > .input-group {
      display: inline-block;
      width: 89%;
    } */
  .help-btn {
    right: 30%;
  }
  .details-page-div {
    position: absolute !important;
    z-index: 9999;
    background-color: rgb(255, 255, 255);
    top: 24%;
    width: 68% !important;
  }
  .modal-dialog.large {
    width: 98%;
  }
  .adv_search {
    height: 166px;
    overflow-x: hidden;
    /* overflow-y: auto; */
  }
  label.page-link {
    visibility: hidden;
    font-size: 0;
  }

  .csl-entry {
    font-size: 14px !important;
  }
  search-result-details {
    border-left: none;
  }
  .modal-dialog.termfrequency-popup {
    width: 93% !important;
  }
  .modal-dialog {
    margin: 30px auto !important;
  }
  #chartContainer {
    min-height: 600px;
    max-height: 650px;
  }
  li.nav-item.docviewer-tabs.active a {
    width: 100%;
  }
  .nav-item.docviewer-tabs {
    width: 100% !important;
    margin-bottom: 3px !important;
  }
  .tab-container ul.nav.nav-tabs {
    border-bottom: 0px;
  }
  .nav-tabs > li > a {
    width: 100%;
  }
  .details-page-div.nodetailPageHeader {
    top: 17%;
  }
  .details-page-div.detailPageHeader {
    top: 34%;
  }
  .borderLeftMenu.searchPageHeader {
    top: 34%;
  }

  .termssearck-list {
    margin-bottom: 12px;
  }
  .modal-dialog.illustration-popup {
    width: 90% !important;
  }
}
#tooltipContentDivision label {
  font-size: 13px;
  font-weight: 600;
  display: block;
}
.disclaimer-contents.margin-bottom0 > pre {
  margin: 0 !important;
  padding: 10px;
}
.disclaimer-content-div pre {
  white-space: pre-wrap;
}

.disclaimer-content-div .docText {
  overflow: auto !important;
  max-height: 170px !important;
  min-height: 150px !important;
  font-size: 16px;
}
.disclaimer-content-div .docTextSize {
  overflow: auto !important;
  max-height: 360px !important;
  min-height: 150px !important;
  font-size: 16px;
}
.disclaimer-content-div .docText::-webkit-scrollbar {
  width: 6px;
}
.disclaimer-content-div .docText::-webkit-scrollbar-thumb {
  background: #61165e;
  border-radius: 25px;
}
.disclaimer-content-div .docTextSize::-webkit-scrollbar {
  width: 6px;
}
.disclaimer-content-div .docTextSize::-webkit-scrollbar-thumb {
  background: #61165e;
  border-radius: 25px;
}
/* .multiselect-dropdown-item .btn-block {
    display: block;
    width: 170px !important;
    text-align: left;
}
.multiselect-dropdown-item .btn-block.caret {
    white-space: nowrap !important;
    float: right !important;
    margin-top: 7px !important;
} */

a.leaflet-draw-edit-edit {
  display: none !important;
}
.mr-b-20 {
  margin-bottom: 20px !important;
}
#manuscriptTree2 tree-node-collection {
  /* padding-top: 20px !important; */
  display: inline-block;
}
wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-last-of-type(1),
wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-last-of-type(1)
  .panel {
  margin-bottom: 0 !important;
}
wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-last-of-type(1)
  .panel
  .panel-body {
  padding-bottom: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sa-info:after {
    left: 40px;
  }
  .show-illustrationimage {
    /* top: 25% !important; */
    right: 24% !important;
  }
}
/* .MapsGridIcon ul{
  margin-top: 41px !important;
} */

/*
* override the .fab font awesome for ngx-share button plugin start
*/
.fab {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

/*
* override the .fab font awesome for ngx-share button plugin end
*/

/* .gridViewSwitch .popover.left {
  top: 17px !important;
} */
@media only screen and (min-width: 1025px) and (max-width: 1232px) {
  /* #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
    right: 14.8% !important;
  } */
  .input-group.archives-menudiv multiselect-dropdown {
    width: 22% !important;
  }

  #searchResultDiv.heatmap-result-leftbar
    .listViewSwitch
    .gridview-panel
    .mobileContentSubType
    .dropdown-menu {
    width: 164px;
    padding: 10px;
    margin-left: 15px;
    margin-top: -15px;
    border-radius: 0;
    min-height: 0;
    max-height: 500px;
    overflow-x: auto;
  }
  #searchResultDiv.heatmap-result-leftbar
    .listViewSwitch
    .gridview-panel
    .mobileContentSubType
    .dropdown-menu::-webkit-scrollbar {
    height: 6px;
  }
  #searchResultDiv.heatmap-result-leftbar .listViewSwitch .contentsubType {
    width: 170px;
    margin-top: 10px;
  }
  .historypop .popover.in.popover-left.bs-popover-left.left,
  .historyActive .popover.in.popover-left.bs-popover-left.left {
    min-width: 670px !important;
    padding: 10px 7px;
    max-width: 670px !important;
    padding: 0;
  }
  .viewlist-listcontainer .popover.left {
    min-width: 670px !important;

    max-width: 670px !important;
  }
  .historyDate {
    width: 130px !important;
  }
  .Manuscriptsclass popover-container.popover-left.bs-popover-left.left,
  .Photographsclass popover-container.popover-left.bs-popover-left.left,
  .Mapsclass popover-container.popover-left.bs-popover-left.left,
  .Monographsclass popover-container.popover-left.bs-popover-left.left {
    border-radius: 0 !important;
    background: #000 !important;
    width: 200px;
  }
  .dropdown-box ul.dropdown-menu-right {
    min-width: 528px;
  }
  ul.dropdown-menu.dropdown-menu-right.dropdown-form {
    max-height: 458px !important;
  }
}
/* @media only screen and (min-width: 1025px) and (max-width: 1880px) {
  .search-result-float-menu-content
    .listViewSwitch.gridview-enable
    .Photographsclass.img_pad
    popover-container.popover-right.bs-popover-right.right {
    left: auto !important;
    right: 55px !important;
  }
} */
app-term-groups .tooltip a.search-result-header-title {
  clear: both;
  display: inline-block;
  width: 100%;
}

/* .accordian-inner.gridviewDiv.Photographsview:nth-last-of-type(-n+5) .popover, */
wiley-search-result
  .panel-group
  > .panel:nth-last-of-type(1)
  .accordian-inner.gridviewDiv:nth-of-type(5n + 1):nth-last-of-type(-n + 5)
  .popover,
wiley-search-result
  .panel-group
  > .panel:nth-last-of-type(1)
  .accordian-inner.gridviewDiv:nth-of-type(5n + 1):nth-last-of-type(-n + 5)
  ~ .gridviewDiv
  .popover {
  /* top: auto !important; */
  bottom: 0;
}

wiley-search-result
  .panel-group
  > .panel:nth-last-of-type(1)
  .accordian-inner:nth-last-of-type(1)
  .popover {
  /* top: auto !important; */
  bottom: 0;
}

/* wiley-search-result
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner:nth-of-type(1)
  .popover {
  top: 17px !important;
  bottom: auto;
  top: -150px !important;
} */
/* wiley-search-result
  .panel-group
  > .panel:last-child
  .accordian-inner:last-child
  .popover {
  bottom: -74px !important;
} */

.mapView-enable.listview-enabled
  .Photographsclass
  popover-container.popover.in.popover-right.bs-popover-right.right {
  /* top:-30px !important; */
  right: 50px !important;
  left: unset !important;
}
.container-fluid.show-docviewer {
  min-height: 86vh;
}

wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner:nth-of-type(1)
  .searchresult-imgdiv:hover
  .tooltipShow
  .tooltip,
wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner:nth-of-type(2)
  .searchresult-imgdiv:hover
  .tooltipShow
  .tooltip {
  top: auto !important;
}

.defaultView
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner:nth-of-type(-n + 5)
  .searchresult-imgdiv:hover
  ~ .tooltipShow
  .tooltip {
  top: auto !important;
}

/* External tooltip css customization for bugs of last child in grid view of map view */
/*
wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner:nth-last-of-type(1) .img_pad .view-icn:hover + tooltip-content .tooltip {
    top: auto !important;
    bottom: -42px;
}

wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.listViewSwitch:nth-last-of-type(1) .img_pad .view-icn:hover + tooltip-content .tooltip {
    top: auto !important;
    bottom: 0px;
    left: 120px !important;
}

wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner:nth-last-of-type(1) .img_pad .view-icn:hover + tooltip-content .tooltip .tooltip-inner {
    padding: 10px 20px;
}

wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.gridviewDiv:nth-last-of-type(1) .search-iconsdiv .gridviewIllustration:hover tooltip-content .tooltip {
    top: -50px !important;
}

wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.gridviewDiv:nth-last-of-type(1) .search-iconsdiv .showgridviewIllustration:hover tooltip-content .tooltip,
wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.gridviewDiv:nth-last-of-type(1) .search-iconsdiv .gridBookmark:hover tooltip-content .tooltip,
wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.gridviewDiv:nth-last-of-type(1) .search-iconsdiv .gridlist:hover tooltip-content .tooltip {
    top: -30px !important;
}

wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.gridviewDiv:nth-last-of-type(1) .search-iconsdiv .gridviewIllustration:hover tooltip-content .tooltip .tooltip-arrow,
wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.gridviewDiv:nth-last-of-type(1) .search-iconsdiv .showgridviewIllustration:hover tooltip-content .tooltip .tooltip-arrow,
wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.gridviewDiv:nth-last-of-type(1) .search-iconsdiv .gridBookmark:hover tooltip-content .tooltip .tooltip-arrow,
wiley-search-result .search-result-float-menu-content .panel-group > .panel:nth-last-of-type(1) .accordian-inner.gridviewDiv:nth-last-of-type(1) .search-iconsdiv .gridlist:hover tooltip-content .tooltip .tooltip-arrow {
    display: none;
} */

.gridViewSwitch .accodion-panel {
  padding-top: 3px !important;
}

/* wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner.gridview-enable:nth-of-type(1)
  .popover,
wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner.gridview-enable:nth-of-type(2)
  .popover {
  bottom: auto;
  left: auto !important;
  right: 60px;
} */
/* wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel
  .accordian-inner.gridview-enable
  .popover {
  left: auto !important;
  right: 60px;
} */
.badge.margin-left5,
.select-qry,
.margin-left5 .badge {
  cursor: default !important;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.archivesSelectList .multiselect-dropdown-item ul.dropdown-menu {
  left: auto !important;
  right: 0 !important;
}
.archivesSelectList .dropdown-toggle .caret {
  float: right;
  margin-top: 8px;
}
.goto-dropdown-overflow {
  max-height: 50vh;
}

.clearFix {
  clear: both;
}

.loadMoreResultsArrow {
  font-size: 40px;
  color: #414246;
}

.viewMoreContent {
  cursor: pointer;
  width: 150px;
  margin: 15px auto 0 auto;
}

.fast-right-spinner {
  -webkit-animation: glyphicon-spin-r 1s infinite linear;
  animation: glyphicon-spin-r 1s infinite linear;
  font-size: 30px;
  margin-top: 20px;
}

@-webkit-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* .archive-btnopen .dropdown-toggle:focus,
.btn-adv-search:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.3);
  outline: 5px auto -webkit-focus-ring-color !important;
} */
.badgeTerms {
  display: block !important;
  margin: 2px 15px;
}

.clearMarginPadding {
  margin: 0px;
  padding: 0px;
}
.heatmap-result-leftbar
  .listViewSwitch
  .search-result-header-title.headerTitle {
  line-height: 1.36 !important;
  padding-bottom: 0;
}
.dropdown-menu.dropdown-menu-right.margin-right15.showList {
  padding: 0 !important;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: none;
}
/* .gridviewDiv ul.search-iconsdiv li.position {
  width: 23.2% !important;
} */
.position-droppin {
  margin: 0 auto;
  position: relative;
  left: 36%;
  top: 18%;
}
.brightnessPopup popover-container {
  display: block !important;
}
.headingnyasicon-default,
.headingnyasicon-defaultWpa {
  background-position: -441px -461px;
}
.view-less-btn-btm {
  margin-bottom: 15px;
}
.pad-btm-10 {
  padding-bottom: 10px !important;
}
.headingrcpicon {
  background-position: -439px -491px;
}
.adv_search {
  height: 145px;
  overflow-y: scroll;
  max-height: 66vh;
}
.adv_search::-webkit-scrollbar {
  width: 6px;
}
.adv_search::-webkit-scrollbar-thumb {
  background: #61165e;

  border-radius: 25px;
}

a:hover,
a:focus {
  color: #23527c;
  text-decoration: none !important;
}
.collapsed-leftmenu {
  width: 0px !important;
}
.gridviewDiv.Photographsview .photographwidth.positions li.position {
  width: 32.6% !important;
}
.gridviewDiv .Photographsshow .photographwidth.positions li {
  padding: 8px 20px !important;
  width: 32.8% !important;
  float: left !important;
  margin: 0 1px;
}
/* PhotographsGridIcon ul.search-iconsdiv.positions li {
  padding: 8px 20px !important;
  width: 32% !important;
  float: left;
  margin: 0 1px;
} */
.gridviewDiv .Photographsshow .photographwidth.positions li:nth-child(3) {
  margin-right: 0;
}
.gridviewDiv .Photographsshow .photographwidth.positions li:nth-child(1) {
  margin-left: 0;
}
/* .MapsGridIcon ul.search-iconsdiv.positions li {
  padding: 8px 20px !important;
  width: 33% !important;
} */
.gridViewSwitch .MapsGridIcon ul.search-iconsdiv.positions li,
.PhotographsGridIcon ul.search-iconsdiv.positions li {
  /* padding: 8px 20px !important; */
  /* width: 32.8% !important; */
  float: left !important;
}
/* .PhotographsGridIcon ul.search-iconsdiv.positions li .mapIcon.fa.fa-map-marker{
  top: -7px;
} */
.gridViewSwitch .MapsGridIcon ul.search-iconsdiv li {
  float: left;
}
.documentsClass {
  margin-top: 8px;
}
.doc-title {
  display: inline;
  font-size: 20px;
  cursor: pointer;
  color: #61165e;
}
.docVisited .doc-title {
  color: #414246 !important;
}
.isFirefox .dropdown-toggle .caret {
  position: relative;
  bottom: 12px;
  margin-top: 0;
}
.isFirefox .firefoxText {
  padding: 4px 6px !important;
}
/* @media only screen and (min-width: 1431px) and (max-width: 1520px) {
  .defaultView
    div.gridViewSwitch:nth-child(5n)
    popover-container.popover-right.bs-popover-right.right {
    left: -60px !important;
  }
  #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
    right: 8.7% !important;
  }
} */
/* @media only screen and (min-width: 1519px) and (max-width: 1659px) {
  #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
    right: 7.8% !important;
  }
} */

/* @media only screen and (min-width: 1660px) and (max-width: 1824px) {
  .defaultView
    div.gridViewSwitch:nth-child(5n)
    popover-container.popover-right.bs-popover-right.right {
    left: -25px !important;
  }
  #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
    right: 8.1% !important;
  }
} */
/* @media only screen and (min-width: 1823px) and (max-width: 1919px) {
  #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
    right: 8.7% !important;
  }
} */

@media only screen and (min-width: 1233px) and (max-width: 1430px) {
  /* .defaultView
    div.gridViewSwitch:nth-child(5n)
    popover-container.popover-right.bs-popover-right.right {
    left: -82px !important;
  } */
  .heatmap-result-leftbar
    .listViewSwitch
    .Photographsshow.col-md-7.accodion-panel {
    width: 100% !important;
  }
  /* #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
    right: 10.7% !important;
  } */
  .historypop .popover.in.popover-left.bs-popover-left.left,
  .historyActive .popover.in.popover-left.bs-popover-left.left {
    min-width: 830px !important;
    padding: 10px 7px;
    max-width: 830px !important;
    /* left: -500px; */
  }
  .viewlist-listcontainer .popover.in.popover-left.bs-popover-left.left {
    min-width: 830px !important;
    padding: 10px 7px;
    max-width: 830px !important;
    /* left: -500px; */
  }
}

/* term frequency popup */
.termfrequency-popup .modal-content {
  border-radius: 0;
  /* margin: auto; */
  padding: 10px 5px;
  width:130%
}
.termfrequency-popup {
  width: 691px !important;
}
.termfrequency-popup .modal-header {
  padding: 5px 10px;
}
.addterm-btn {
  margin: 15px 0;
  margin-top: 15px;
  height: 35px !important;
}
.termBox {
  height: 35px !important;
}
/* .term-searchResult {
    padding-top: 15px !important;
    margin-top: 15px;
} */
.term-searchResult-border {
  border-top: 2px solid #61165e;
}
.searchterm-title {
  text-align: left;
  width: 100%;
  display: inline-block;
}
.term-title {
  font-weight: 600;
  font-size: 22px !important;
  margin-bottom: 15px;
  font-weight: bold;
  color: #141414;
  font-size: 22px;
  font-family: "Source Serif Pro", "serif" !important;
}
.term-left {
  text-align: left;
  color: #000;
  font-weight: 600;
  position: relative;
  display: block;
  float: left;
  margin-bottom: 11px;
  margin-right: 10px;
  background: #dedede;
  width: 100%;
}
.term-left .select-qry {
  background: #ededed;
  height: 30px !important;
  display: inline-block;
  font-weight: 600 !important;
  line-height: 25px;
  background: transparent !important;
  border-right: 1px solid #000;
}
.term-left .float-right {
  margin: 4px 0;
}
.addterm-icon {
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
}
.term-footer {
  text-align: right;
}
.chart-btn {
  text-align: center;
  margin-right: 9px;
  height: 35px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.term-modal-body {
  height: 364px;
  text-align: center;
  font-size: 25px;
  /* display: inline-block; */
  /* position: relative; */
  background-color: white !important;
}
.term-footer {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}
.maximum-termlength,
.maximum-termlength .addterm-icon {
  cursor: not-allowed;
  pointer-events: none;
}
.term-searchResult {
  margin-left: 14px;
}
.chart-btn.disabled {
  pointer-events: none;
}
.hidecontentTypeTooltip tooltip-content,
.hideCollectionTooltip tooltip-content,
.hidecontentSubTypesTooltip tooltip-content,
.hidepublicationTitleTooltip tooltip-content,
.hidecontentSourcesTooltip tooltip-content,
.hideArchivesTooltip tooltip-content {
  display: none;
}
.wileyLogoMRL {
  margin-left: 15px;
}
.display-badge {
  width: 90px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  min-height: 13px;
  padding-top: 3px;
}

wiley-header .popover-content.popover-body {
  min-width: 112px;
}
.list-view-container
  .list-view-content
  .list-view-item.irrelevant-img:last-child {
  margin-bottom: 4%;
}
.no-pad-btm {
  padding-bottom: 0;
}
.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
/* .toggleArrowShow{
  display: block !important;
}
.toggleArrowHide{
  display: none !important;
} */
.tools-badge {
  text-transform: capitalize;
  border-radius: 50% !important;
  padding: 3px 5px !important;
  font-weight: 800;
  margin-top: -1px;
  color: #fefefe !important;
  background-color: #61165e !important;
  text-align: center;
}
.searchQryIcon {
  display: inline-block;
  position: absolute;
  margin-left: 15px;
}
/* .addthis-smartlayers.addthis-smartlayers-desktop {
    display: none;
} */

/* .addthis-smartlayers.addthis-smartlayers-desktop,
.at-share-dock-outer.addthis-smartlayers.addthis-smartlayers-mobile .slideInUp.addthis-animated {
    display: none;
} */

.close-icon-search {
  position: absolute;
  right: 45px;
}
.notification .docTitles .docTitle,
.docTitles .titleRemovedFromList {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  display: inline-block;
  overflow: hidden;
  position: relative;
  max-height: 2.5em;
  padding-right: 2px;
  /* margin-right: -1em;
  padding-right: 1em; */
}
.notification .docTitles .docTitle::before,
.docTitles .titleRemovedFromList::before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}
.notification .docTitles .docTitle::after,
.docTitles .titleRemovedFromList::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
}
/* .gridViewSwitch.Manuscriptsview li.position:nth-child(3),
.gridViewSwitch.Manuscriptsview li.position:nth-child(2) {
  margin-left: 1% !important;
  margin-right: .20% !important;
  /* margin-right: .35% !important;
} */

#at15s.atm,
span.at4-share-count-container,
li.at3winsvc_email.top-service,
a.at-branding-logo.at-expanded-menu-branding {
  display: none !important;
}
.highlight-limitors .dropdown-toggle {
  color: #61165e !important;
  font-weight: 600;
}
.highlightpubs-limitors {
  color: #61165e !important;
  font-weight: 600;
}
.limiter-popup > .popover {
  max-width: 500px !important;
  /* left: auto !important; */
  /* right: 15px; */
  border-radius: 0px !important;
  margin-top: 3px !important;
}
#photo_explorer
  popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
  max-width: 500px !important;
  /* left: 0px !important; */
  /* right: 5.8%; */
  border-radius: 0px !important;
  margin-top: 0 !important;
}
.lightbox.animation.fadeIn,
.lightbox.animation.fadeOut {
  top: 20% !important;
}

.lb-dataContainer {
  background: #fff;
  position: absolute;
  top: -50px;
  left: 15%;
  border-radius: 0 !important;
}
.lb-outerContainer.transition {
  border-radius: 0 !important;
}
.lightbox .lb-image {
  border-radius: 0 !important;
}
.lb-outerContainer.transition {
  /* height: 600px !important; */
  top: 0 !important;
}
#photo_explorer span.hideArchivesTooltip {
  display: none !important;
}
.translate-downicn {
  margin-top: 3px;
}
.text-white {
  color: #fff;
}
#wiley-menu-archive-content {
  z-index: 1070;
}
.download-document .modal-content {
  width: 100%;
  margin-top: 0 !important;
}
/* make modal z-index as higher start */
.modal-backdrop {
  z-index: 1300 !important;
}
.modal {
  z-index: 1310 !important;
}
/* .confirm-button {
    right: -15%;
    bottom: -35%;
    top: 100% !important;
    left: 100% !important;
    cursor: pointer;
}
.cancel-button {
    bottom: -35%;
    top: 100% !important;
    left: 91% !important;
    cursor: pointer;
} */
.confirm-button,
.cancel-button {
  cursor: pointer;
  background-color: #61165e !important;
  border-radius: 50px !important;
}
.confirm-button {
  left: 48% !important;
}
.confirm-button img,
.cancel-button img {
  width: 30px;
  padding: 6px !important;
}
.confirm-button:hover,
.cancel-button:hover {
  background-color: #fff !important;
}
.viewlist-listcontainer .popover.left {
  margin-left: 1px !important;
  min-width: 960px !important;
}
.clipimage-popupbody .carousel-caption {
  bottom: 0 !important;
  color: #000 !important;
  font-size: 18px !important;
  padding: 0 !important;
}
/*.clipimage-popupbody .icon-prev.carousel-control-prev-icon, .icon-next.carousel-control-next-icon {
    color: #61165E;
    cursor: pointer;

}
.clipimage-popupbody .carousel-control.left, .carousel-control.right {
    background-image: none !important;
}
.clipimage-popupbody .icon-prev.carousel-control-prev-icon {
    font-size: 40px;
    left: -25px;
    top: 190px;
}
.clipimage-popupbody .icon-next.carousel-control-next-icon {
    font-size: 40px;
    right: -25px;
    top: 190px;
}

*/
.clipimage-title {
  color: #61165e;
  font-size: 22px;
}
.clipimage-imagesource {
  display: inline-block;
  width: 100%;
  margin: 6px 0 0;
}
.clipimages-citation {
  margin: 20px 0 0 0;
  text-align: left;
}
.clipimage-imagenotes {
  padding-top: 5px;
  font-size: 16px;
  overflow-wrap: break-word;
}
.cliped-image-details {
  float: left;
  /* margin: 10px 0 0 14px; */
  font-size: 20px;
  font-weight: 600;
}
.mandatory-icon {
  color: #ff0000;
  font-size: 11px;
  position: absolute !important;
  left: 58px;
  top: 19px !important;
}

/* for clip image rotate */
.clipimage-imagenotes label {
  margin-bottom: 0px;
}
.openseadragon-canvas.custom-cursor {
  cursor: se-resize !important;
}
.clipimage-popupbody .carousel-indicators {
  display: none;
}
.rotate-90deg img {
  transform: rotate(90deg);
}
.rotate-180deg img {
  transform: rotate(180deg);
}
.rotate-270deg img {
  transform: rotate(270deg);
}
.rotate-0deg img {
  transform: rotate(0deg);
}
.rotate-m90deg img {
  transform: rotate(-90deg);
}
.rotate-m180deg img {
  transform: rotate(-180deg);
}
.rotate-m270deg img {
  transform: rotate(-270deg);
}
.rotate-0deg img {
  transform: rotate(0deg);
}
.helpguide popover-container.popover.in.popover-left.bs-popover-left.left {
  min-width: 185px !important;
  /* left: -186px !important; */
}

li.nav-item.tableofcontents-tabs.bytype-tab.types-tabset {
  width: auto !important;
}
li.nav-item.tableofcontents-tabs.byitem-tab.items-tabset {
  width: auto !important;
}
.modal-dialog.large.docview-popup {
  width: 85%;
}
.fa.fa-close.docview-closebtn {
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  top: 10px;
}
.tab-search .active a.nav-link.active span,
.tab-search .nav-tabs > li > a {
  color: #333 !important;
  font-weight: 600;
}

.tab-search li.nav-item.active a {
  background: #fefefe !important;
}
.tab-search .archive-btnopen .dropdown-toggle {
  background-color: #fefefe !important;
  border-right: 1px solid #efeff0 !important;
}
.tab-search .nav-item {
  background-color: #e7e7ea;
}
/* .tab-search .adv_search {
    margin-top: 9px;
} */
.reset_link {
  font-weight: normal;
  margin-top: 5px;
  color: #0505ef;
  cursor: pointer;
}

.reset_link:hover {
  text-decoration: underline;
}
@media (min-width: 1200px) and (max-width: 1762px) {
  .col-md-1.no-padding {
    margin-top: 0;
  }
  .full-text-wrapper .popover-content {
    /* max-height: 300px !important; */
    /* overflow-y: auto !important; */
    padding: 5px 5px;
  }
  .full-text-wrapper .popoverUl {
    padding: 5px 0px !important;
  }

  .text_justify,
  .privacy,
  .cookies,
  .termsAndConditions {
    text-align: justify;
  }

  .text_left {
    text-align: left;
  }

  ul.dropdown-menu.shareWidth.share_left {
    left: auto;
    right: 0 !important;
  }
}
/* .full-text-wrapper .popover-content {
    max-height: 100px;
    max-height: 50vh !important;
    min-height: 100px;
    overflow-y: auto !important;
} */
.modal-backdrop.fade.in.removeBackdrop {
  opacity: 0 !important;
  position: relative !important;
}
.backdrop-modal.activeModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
}
a.left.carousel-control.carousel-control-prev,
a.left.carousel-control.carousel-control-prev.disabled,
a.right.carousel-control.carousel-control-next,
a.right.carousel-control.carousel-control-next.disabled {
  width: 0% !important;
}

/*
* pagination override  section start
*/
.pagination-wrapper .pagination {
  margin: 10px 0px 10px 10px !important;
  border-radius: 0px !important;
}
.pagination-wrapper .pagination > li:first-child > a,
.pagination-wrapper .pagination > li:first-child > span {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.pagination-wrapper .pagination > li:last-child > a,
.pagination-wrapper .pagination > li:last-child > span {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.pagination-wrapper .pagination > .active > a,
.pagination-wrapper .pagination > .active > span,
.pagination-wrapper .pagination > .active > a:hover,
.pagination-wrapper .pagination > .active > span:hover,
.pagination-wrapper .pagination > .active > a:focus,
.pagination-wrapper .pagination > .active > span:focus {
  background-color: #61165e;
  border-color: #61165e;
  color: #fff;
}
.pagination-wrapper .pagination > li > a,
.pagination-wrapper .pagination > li > span {
  color: #61165e;
}
.pagination-wrapper .pagination > li > a:hover,
.pagination-wrapper .pagination > li > span:hover,
.pagination-wrapper .pagination > li > a:focus,
.pagination-wrapper .pagination > li > span:focus {
  color: #61165e;
}
.form-control:focus {
  border-color: #61165e !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(97, 22, 94) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(97, 22, 94) !important;
}
/*
* pagination override  section end
*/

/* illustration tree view style */
#illustrationTree
  .toggle-children-wrapper-collapsed
  span.toggle-children:before,
#illustrationTree .toggle-children-wrapper-expanded span.toggle-children:after {
  top: -13px !important;
}
.image-viewer-parent {
  display: inline-block;
}
.openseadragon-canvas {
  background: #adaeb0 !important;
}
.result-details-p .csl-entry {
  font-size: 14px !important;
}
span.ng-toggle-handle.btn.btn-light {
  background: #6b626b;
  border-right: 2px solid #796f79;
}
#illustrationTree .node-content-wrapper:hover {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* tabset leftmenu */
ul.nav.nav-stacked.flex-column.nav-pills {
  float: left;
  background: #61165e;
  height: 75vh;
  padding-top: 5px;
  width: 75px;
}

/*Left menu*/
#leftMenuTab li.nav-item {
  width: 95%;
  text-align: center;
  /* padding: 7px 0 0 0; */
  margin-left: 5px;
  background: transparent !important;
}
#leftMenuTab a.nav-link span.spriteico {
  margin-left: 25% !important;
}
#leftMenuTab li.nav-item.active,
#leftMenuTab li.nav-item:hover {
  background: #f7f7f7 !important;
}
#leftMenuTab li.nav-item:hover a.nav-link,
#leftMenuTab li.nav-item.active a.nav-link {
  color: #61165e;
}
#leftMenuTab li.nav-item.active a {
  background: #f7f7f7 !important;
}
/* #leftMenuTab a.nav-link.active {
    background-color: #f7f7f7 !important;
}
#leftMenuTab a.nav-link:hover{
    background-color: #f7f7f7 !important;
} */
#leftMenuTab a.nav-link {
  display: block;
  padding: 0 !important;
  font-size: 10px;
  color: #f7f7f7;
  width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 8px !important;
  padding-bottom: 5px !important;
}
#leftMenuTab a.nav-link label {
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
#leftMenuTab .tab-content {
  float: right;
  width: 383px;
  padding: 0 10px;
  background: #f7f7f7;
  margin-left: 75px;
  position: absolute;
}
#leftMenuTab .row.image-viewer-parent {
  width: 100%;
  margin: 0 !important;
}
#leftMenuTab a.nav-link:hover {
  background-color: transparent !important;
}
#leftMenuTab li.nav-item a:focus {
  background: transparent !important;
}
/* #leftMenuTab li.nav-item {
    background: none !important;
    margin-right: 0 !important;
} */
.active a.nav-link.active .documentIcon,
.nav > li:hover > a .documentIcon {
  background-position: -38px -538px;
}
.active a.nav-link.active .citationIcon,
.nav > li:hover > a .citationIcon {
  background-position: -440px -601px;
}
.active a.nav-link.active .itemsIcon,
.nav > li:hover > a .itemsIcon {
  background-position: -75px -537px;
}
.active a.nav-link.active .illustrationIcon,
.nav > li:hover > a .illustrationIcon {
  background-position: -109px -535px;
}
.active a.nav-link.active .relatedsubIcon,
.nav > li:hover > a .relatedsubIcon {
  background-position: -156px -538px;
}
.active a.nav-link.active .tocIcon,
.nav > li:hover > a .tocIcon {
  background-position: -521px -540px;
}
.active a.nav-link.active .searchWithinIcon,
.nav > li:hover > a .searchWithinIcon {
  background-position: -440px -538px;
}
.active a.nav-link.active .notesIcon,
.nav > li:hover > a .notesIcon {
  background-position: -403px -600px;
}
.toggled-menu-list ul {
  width: 100% !important;
}
/* for thead arrow issue in history tab */
.history_table.table > thead > tr > th {
  padding: 4px;
}

.ng-toggle-wrapper.ng-toggle-animate .ng-toggle-container {
  width: 204px !important;
}
.ng-toggle-wrapper {
  width: 109px !important;
}
.ng-toggle-on,
.ng-toggle-off,
.ng-toggle-handle {
  width: 94px !important;
}
@media only screen and (min-width: 1441px) and (max-width: 1620px) {
  #leftMenuTab .tab-content {
    float: right;

    padding-right: 5px;
  }
}
@media only screen and (min-width: 1621px) and (max-width: 1780px) {
  #leftMenuTab .tab-content {
    float: right;

    padding-right: 5px;
  }
}
@media only screen and (min-width: 1246px) and (max-width: 1440px) {
  #leftMenuTab .tab-content {
    float: right;

    padding-right: 5px;
  }
  ul.nav.nav-stacked.flex-column.nav-pills {
    float: left;
  }
  #itemsTabset ul li.nav-item a {
    font-size: 14px !important;
    padding: 5px !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1127px) {
  ul.nav.nav-stacked.flex-column.nav-pills {
    /* width: 20%; */
    float: left;
  }
  #leftMenuTab .tab-content {
    float: right;
  }
  #manuscriptTree1 .node-content-wrapper {
    width: 93%;
  }
}
@media only screen and (min-width: 1128px) and (max-width: 1245px) {
  #leftMenuTab .tab-content {
    float: right;
    padding-right: 5px;
  }
  ul.nav.nav-stacked.flex-column.nav-pills {
    float: left;
  }
  #itemsTabset ul li.nav-item a {
    font-size: 14px;
    padding: 5px !important;
  }
}

.items-toggle .ng-toggle-on,
.ng-toggle-off,
.ng-toggle-handle {
  width: 96px !important;
}
#itemsTabset .tab-content {
  width: 370px !important;
  padding-top: 10px !important;
  margin: 0;
  padding-right: 0;
}
#itemsTabset li.nav-item.active a {
  background: #61165e !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
}
#itemsTabset ul li.nav-item a {
  padding: 5px !important;
  background-color: #efeff0;
  border-radius: 0 !important;
  color: #61165e !important;
  margin-left: 0 !important;
  width: 100%;
  font-size: 16px;
  text-align: center;
  display: block;
}
/* #itemsTabset li.nav-item {
    width: 50%;
} */
#itemsTabset ul li.nav-item {
  width: 49%;
  margin: 0px;
  padding: 0;
  float: left;
}
.document-view-pagination-list li:first-child {
  margin-left: 0 !important;
}
@media only screen and (max-width: 1026px) and (min-width: 865px) {
  .set-full-width {
    top: 0;
  }
  /* .float-left {
        width: 100% !important;
    } */
}
.matched-data-list span {
  background: #f9f982;
}
.well,
.alert {
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  #itemsTabset .tab-content {
    width: 290px !important;
  }

  #leftMenuTab .tab-content {
    position: absolute;
    width: 300px;
    z-index: 1070;
    height: 74.5vh;
  }
  .tab-pane > tabset > .tab-content {
    height: auto !important;
  }
  /* #leftMenuTab.toggled-menu-list .tab-content {
    position: relative !important;
  } */
  /* toggled-menu-list */
  .itemsIcon-tabs #itemsTabset .tab-content {
    left: 0 !important;
    position: inherit;
    margin-left: -2px;
    height: auto;
  }
  .toggled-menu-list .tab-content {
    height: auto !important;
  }
  /* #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
    right: 0px !important;
  } */
}
.csl-entry i {
  font-weight: 100;
  font-style: normal !important;
}
.share-doc-message {
  text-align: justify;
}
.well {
  margin-bottom: 10px !important;
}
/* remove cancel text in heat-map */
.leaflet-touch .leaflet-right .leaflet-draw-actions {
  display: none !important;
}
.lh-17 {
  padding-top: 6px;
  /* line-height: 17px !important; */
  line-height: 24px !important;
  /* font-weight: 600 !important; */
}
.fb-700 {
  font-weight: 700 !important;
}
#monographTree tree-node .node-content-wrapper {
  width: 90% !important;
}
#monographTree tree-node-children .node-content-wrapper {
  width: 90% !important;
}
#monographTree .toggle-children-wrapper-expanded span.toggle-children:after,
#monographTree .toggle-children-wrapper-collapsed span.toggle-children:before {
  top: -15px;
}
/* accordion implemntation for details page left menu */
.panel-open a.header-items-label {
  color: #fff;
}
.details-accordion-header .panel-heading.card-header {
  padding: 10px 10px;
}
.details-accordion-header.panel.panel-open {
  background: #f7f7f7 !important;
}
.details-accordion-header .panel-heading.card-header {
  background: #f7f7f7 !important;
}
a.header-items-label {
  color: #000;
}
.details-accordion-header a.header-items-label {
  color: #000 !important;
}
.header-accordion-upicn {
  color: #000 !important;
}
.details-accordion-header .panel.card.panel-default {
  border-bottom: 1px solid #000 !important;
}
/* hide mouse click if highlight off in illustration tables */
.hide-mouse-action {
  pointer-events: none;
}
.document-view-relavantItems .panel-group {
  margin-bottom: 5px !important;
}
.document-view-relavantItems .panel-heading {
  padding: 7px 10px !important;
}
@media only screen and (min-width: 1025px) and (max-width: 1600px) {
  #leftMenuTab .tab-content {
    width: 300px;
  }
  #itemsTabset .tab-content {
    width: 290px !important;
  }
}

p.tabicon-title {
  margin-bottom: 0;
  width: 65px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.image-viewer-table.hide-mouse-action {
  background: none !important;
}

#illustrationTree
  tree-node-children
  .tree-node
  .child-node:hover
  .download-illustration {
  display: block;
}

.at-share-btn-elements {
  width: 368px;
}
.at-resp-share-element.at-mobile .at-share-btn {
  margin-right: 2px !important;
}
.image-photo-highlight.hide-mouse-action {
  background: none !important;
}
.display-block-text a {
  display: block;
  color: #61165e !important;
  margin: 5px 0 !important;
}
.document-type-list .display-block-text {
  list-style-type: none;
}

.no-margin {
  margin: 0px !important;
}
/* @media only screen and (min-height: 540px) and (max-height: 750px) {
  .viewlist-listcontainerinner.feedbacks
    .popover.in.popover-left.bs-popover-left.left {
    top: -124px !important;
  }
} */
@media only screen and (min-width: 1024px) and (max-width: 1232px) {
  /* .historypop .popover.in.popover-left.bs-popover-left.left,
  .historyActive .popover.in.popover-left.bs-popover-left.left {
          min-width: 830px !important;
          padding: 10px 7px;
          max-width: 830px !important;
          left: -500px;
      } */
  .viewlist-listcontainerinner.feedbacks
    .popover.in.popover-left.bs-popover-left.left,
  .viewlist-listcontainer .popover.in.popover-left.bs-popover-left.left {
    min-width: 540px !important;
    padding: 10px 7px;
    max-width: 540px !important;
    /* left: -500px; */

    overflow: auto;
  }
  .viewlist-listcontainerinner.feedbacks .feedback-scroll {
    max-height: 400px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .viewlist-listcontainerinner.feedbacks
    .popover.in.popover-left.bs-popover-left.left,
  .viewlist-listcontainer .popover.in.popover-left.bs-popover-left.left {
    min-width: 400px !important;
    padding: 10px 7px;
    max-width: 400px !important;
    /* left: -500px; */

    overflow: auto;
  }
  .viewlist-listcontainerinner.feedbacks .feedback-scroll {
    max-height: 400px;
  }
  /* .viewlist-listcontainerinner.feedbacks
    .popover.in.popover-left.bs-popover-left.left {
    top: -109px !important;
  } */
}
/* image viewer grid view thumbnail images start */
.grid-thumbnails {
  position: absolute;
  top: 13px;
  right: 0;
  cursor: pointer;
}
.slim-strip-container.gridview-images {
  width: 100% !important;
}
.gridview-images .tab-content {
  /* width: 95% !important; */
  width: calc(100% - 75px) !important;
}
.gridview-images .doc-right {
  display: none;
}
.gridview-images ul.document-view-pagination-list {
  text-align: left !important;
}
.gridview-images .filmstrip-container-div,
.gridview-images .relevant-image-container-div {
  width: 110px !important;
  height: 95px !important;
}

@media only screen and (max-width: 767px) {
  /* .viewlist-listcontainerinner.feedbacks
    .popover.in.popover-left.bs-popover-left.left {
    top: -7px !important;
  } */
  .viewlist-listcontainer .popover.in.popover-left.bs-popover-left.left,
  .helpguide popover-container.popover.in.popover-left.bs-popover-left.left {
    padding-bottom: 10px;
    position: relative;
    width: 100% !important;
    min-width: 100% !important;
    margin: 0 !important;
    left: 0 !important;
    float: left;
    cursor: default;
    /* top: -8px !important; */
  }
}
.hide-keyword-overlay,
.image-keyword-highlight.no-match,
.hide-headword-overlay {
  background: none !important;
}
.arabic-style {
  direction: rtl;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
}
.document-type-list {
  text-align: left !important;
}
.active a.nav-link.active .volumeIcon,
.nav > li:hover > a .volumeIcon {
  background-position: -200px -146px;
}
.modal-body.warning_bdy.arabic-style {
  text-align: right !important;
}
/* .nav > li > a:hover, .nav > li > a:focus{
    background-color: transparent !important;
} */
.homePageSearch a.nav-link {
  padding: 0 !important;
}
.homePageSearchLabel {
  padding: 10px 15px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 600;
}
.nav-tabs > li.homePageSearch.active > a,
.nav-tabs > li.homePageSearch.active > a:focus {
  color: #333 !important;
}
.viewlist-listcontainerinner.feedbacks .popover-content {
  padding: 0;
}
.angular-tree-component::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.angular-tree-component::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  height: 6px;
}

.angular-tree-component::-webkit-scrollbar-thumb {
  background-color: #61165e;
  border-radius: 25px;
}
.nav > li.docs-tab > a:hover,
.nav > li.doc-tabs > a:focus {
  text-decoration: none;
  background-color: #eee0 !important;
}
/* .downloadPopupIcon{
    background-image: url(/assets/images/icons/download@2x.png);
} */
.download-text {
  text-align: center;
  font-size: 30px;
  text-decoration: underline;
}
.download-text-cmt {
  text-align: center;
  margin-top: 5px;
}
.ps--active-x > .ps__rail-x {
  display: block;
  background-color: transparent;
  left: 0 !important;
}
span.modal-download-text-span {
  font-size: 16px;
  margin-top: 15px;
  display: block;
}
.warning-message.arabic-style {
  text-align: right !important;
}
.filter-content span.badge-count {
  margin-right: 15px;
  padding: 0;
}
.language-popover-scroll {
  max-height: 300px !important;
  /* overflow-y: auto !important; */
}
.full-text-wrapper.language-popover .popover-content {
  padding: 0px 2px 0 10px;
}
/* bookmark url heading style */
.bookmark-header {
  font-weight: 600;
  font-size: 22px;
}

.wiley-homePageSearch {
  background: #005274 !important;
  margin-left: 45px;
  cursor: pointer !important;
}
.wiley-homePageSearch a{
  background: #005274 !important;
  cursor: pointer !important;
}
.wiley-homePageSearch .nav-link {
  margin-bottom: -5px;
  color: #fff !important;
}
.wiley-digital-master-tab {
  color: #fff !important;
}
.wiley-homePageSearch .nav-link:hover,
.wiley-homePageSearch .nav-link:active {
  background: #005274 !important;
}
.tab-search .wiley-homePageSearch.nav-item.active a {
  background: #005274 !important;
}
.wiley-homePageSearch.tab-pane.active {
  margin-left: 0 !important;
}
label.wiley-digital-master-tab {
  cursor: pointer !important;
}
/* .overlay-search .wiley-homePageSearch-inputbox {
    width: 1600px !important;
} */
.btn.btn-primary.search-btn.wol-search-btn {
  background: #005274 !important;
  border: 1px solid #005274 !important;
  /* width: 120px !important; */
}
.archives-search-textbox.wiley-homePageSearch-inputbox:active,
.archives-search-textbox.wiley-homePageSearch-inputbox:focus {
  border: 1px solid #005274 !important;
  border-color: #005274 !important;
}
app-wiley-searchresult-leftmenu-component {
  padding-right: 5px;
}

/* photo-explorer styles */
.lb-data .lb-caption {
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  color: #000;
}
.lb-data .lb-number {
  font-size: 14px !important;
  color: #5d5b5b !important;
  margin-top: 10px !important;
}
.lb-closeContainer {
  top: -35px;
  position: absolute;
  right: 0;
  background: #fff;
  padding: 3px 9px;
}
.modal-body.photoexplorer-body {
  padding: 0 !important;
  border-radius: 0 !important;
}
.modal-dialog.modal-sm.large.photo-explorer-large .modal-content {
  border-radius: 0 !important;
}
.img-container.photo-exp-image-div {
  background: #414246;
  padding: 15px;
  height: 62vh;
}
ol.carousel-indicators {
  display: none !important;
}
.carousel-control .icon-next:before {
  padding: 15px;
}
.carousel-control .icon-prev:before {
  padding: 13px;
}
.photo-explorer-large span.icon-next.carousel-control-next-icon {
  background: #fff;
  border-radius: 50px;
  font-size: 31px;
  width: 40px;
  height: 40px;
  left: -50px;
  color: #000;
  cursor: pointer;
  box-shadow: -1px 1px 6px 1px #484545;
  border: 2px solid #292729 !important;
}
.photo-explorer-large span.icon-prev.carousel-control-prev-icon {
  background: #fff;
  border-radius: 50px;
  font-size: 31px;
  width: 40px;
  height: 40px;
  left: 25px;
  color: #000;
  cursor: pointer;
  box-shadow: -1px 1px 6px 1px #484545;
  border: 2px solid #292729 !important;
}
.photo-exp-image-div img.img-responsive.center-block {
  height: auto;
  max-height: 100%;
  /* padding: 25px 35px; */
}
p.photoexplorer-citation {
  font-size: 14px;
  text-align: left;
  font-style: italic;
  margin-bottom: 0;
}
.photoexp-titles-div {
  padding: 10px 20px;
}
.modal-dialog.modal-sm.large.photo-explorer-large {
  width: 55%;
  top: 25% !important;
}
.display-help,
.display-burgermenu {
  display: block !important;
}
.hideillustrationTypeTooltip tooltip-content,
.hideillustrationTypeTooltip tooltip-content {
  display: none;
}
.download-status-text {
  font-size: 18px;
  text-align: center;
}
a.clipimage-imagesource.clippedimage-source {
  float: none;
  display: inline;
  margin-left: 10px;
  line-height: 20px;
}
app-map-explorer .ps {
  width: 100% !important;
  padding-bottom: 50px !important;
}

.modal-dialog.modal-sm.large.photo-explorer-large {
  width: 70%;
  top: 25% !important;
}
.modal-dialog.modal-sm.large.photo-explorer-large {
  width: 70%;
  top: 25% !important;
}
.explorer-popup {
  width: 25% !important;
}

@media (min-width: 852px) and (max-width: 1700px) {
  .map-exp-content a,
  .list-label {
    display: none !important;
  }
}
/* .tabtext {
  float: right !important;
} */
.tabtext:hover {
  background-color: none !important;
}
.nav-tabs > li.tabtext > a:hover {
  background-color: #fff !important;
  color: #230822;
  margin-top: 0 !important;
  cursor: default !important;
}
.nav-tabs > li.tabtext > a {
  background-color: #fff !important;
  color: #230822 !important;
  margin-top: 0 !important;
  margin-right: 0px !important;
  cursor: default !important;
}

#explorerOpenSeaDragonCanvas,
#explorerOpenSeaDragonCanvas .openseadragon-canvas {
  background: #414246 !important;
}
app-photo-explorer .ps {
  width: 100% !important;
  /* padding-bottom: 50px !important; */
  padding-bottom: 11px !important;
}
.photoexplorer-close-btn:focus,
.photoexplorer-close-btn span:focus {
  outline: none !important;
}
.citation-icon {
  background-position: -561px -315px;
  height: 16px;
  width: 16px;
}
.documentview-icon {
  background-position: -562px -365px;
  display: inline-block;
  height: 15px;
  width: 16px;
}
@media only screen and (min-width: 320px) and (max-width: 450px) {
  .Photographsclass popover-container.popover-right.bs-popover-right.right {
    width: 110px !important;
  }
}
@media only screen and (min-width: 451px) and (max-width: 768px) {
  .Photographsclass popover-container.popover-right.bs-popover-right.right {
    width: 140px !important;
  }
  .csl-entry {
    overflow: unset !important;
    text-overflow: unset !important;
    -webkit-line-clamp: unset !important;
  }
}
.documents-tab-view.document-view-parent.scrollFour
  .search-doc
  .ps.ps--active-y {
  max-height: 35vh !important;
}
.analysis-popup {
  width: 50% !important;
}
/* .analysis-results-div {
  min-height: 60vh;
    max-height: 60vh;
} */
.analysis-header {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0;
}
.analysisListTable {
  text-align: left;
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .wiley-homePageSearch {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 320px) {
  .wiley-homePageSearch {
    margin-left: 0 !important;
    width: 100%;
  }
}
.word-cloud svg {
  width: 100% !important;
}
.wiley-homePageSearch :focus {
  background: #005274 !important;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .scrollTopPage {
    position: fixed;
    right: 0px !important;
    bottom: 90px !important;
    z-index: 1071;
  }
}
.leaflet-interactive {
  cursor: default !important;
}
.photoexplorer-captions.toggledDisclaimer.disclaimer-div .tooltip.top {
  padding: 5px 0;
  margin-top: -16px;
  left: 15px !important;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
    max-width: 350px !important;
    /* right: -2px !important; */
  }
  /* #photo_explorer popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom .popover-content>div{
        width: 370px !important;
      } */
  #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom
    .popover-content {
    padding: 0px 0px !important;
  }
}
/* #leftMenuTab
.nav>li>a:focus {
  background-color: inherit !important;
} */
/* @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none),
  (max-width: 2500px) {
  .advSerach_scrollbar .ps__rail-x, .advSerach_scrollbar .ps__rail-x .ps__thumb-x,.citation-data-container.show-citationContainer .ps__rail-x{
        display: none !important;
    }
} */
.wol-tab {
  height: 40px !important;
}
/* wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner.gridview-enable.mapView-enable:nth-of-type(2)
  .popover {
  top: -31px !important;
} */
#openSeaDragonCanvas.rotateFirst .confirm-button img {
  transform: rotate(-90deg);
}
#openSeaDragonCanvas.rotateSecond .confirm-button img {
  transform: rotate(-180deg);
}
#openSeaDragonCanvas.rotateThird .confirm-button img {
  transform: rotate(-270deg);
}
#openSeaDragonCanvas.rotateFirstReverse .confirm-button img {
  transform: rotate(0deg);
}

.term-frequency_slider .ui-slider .ui-slider-handle {
  height: 1em;
}

.term-frequency_slider .ui-slider-horizontal {
  height: 0.6em;
}
.term-frequency_slider .ui-slider-horizontal .ui-slider-range-min,
.term-frequency_slider span.ui-slider-handle.ui-corner-all.ui-state-default {
  background: #61165e !important;
}
/* .limitor-dpdn-btn .limitors-dropdown-lbl {
    padding: 6px 0 !important;
} */
/* For Taucharts start */
/* .tau-chart__svg .tick text {
    font-size: 13px;
    text-transform: capitalize;
} */
/* .tau-chart__layout .tau-crosshair__label__text,
.tau-chart__layout .tau-crosshair__label__text-shadow {
    font-size: 14px;
    text-transform: capitalize;
}
.tau-chart__layout text {
  font: normal 13px 'Open Sans', sans-serif;
} */
/* For Taucharts and */

/* .term-frequency_slider .slider-control {
  background: #f7e6f7;
} */
.ui-slider-range.ui-corner-all.ui-widget-header {
  background: #61165e !important;
}
app-drill-down-chart
  app-range-slider
  .slider-control.ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content {
  width: 97% !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  background: #61165e
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAGQEAAAAAAao4lEAAAAAmJLR…RkYXRlOm1vZGlmeQAyMDE3LTEwLTI1VDEzOjEwOjU0KzAwOjAw+BvXqwAAAABJRU5ErkJggg==)
    50% 50% repeat-x;
  background: #61165e !important;
}
/* #leftMenuTab .tab-content {
    min-height: 74vh;
} */
#itemsByType,
#itemsInOrder,
#illustrationsTabset,
#itemsInTOCTab {
  min-height: 50vh;
  max-height: 56vh;
  overflow: auto;
}
#itemsByType::-webkit-scrollbar-track,
#itemsInOrder::-webkit-scrollbar-track,
#illustrationsTabset::-webkit-scrollbar-track,
#itemsInTOCTab::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(44, 44, 44, 0.3);
  background-color: #f5f5f5;
}

#itemsByType::-webkit-scrollbar,
#itemsInOrder::-webkit-scrollbar,
#illustrationsTabset::-webkit-scrollbar,
#itemsInTOCTab::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  height: 6px;
}

#itemsByType::-webkit-scrollbar-thumb,
#itemsInOrder::-webkit-scrollbar-thumb,
#illustrationsTabset::-webkit-scrollbar-thumb,
#itemsInTOCTab::-webkit-scrollbar-thumb {
  background-color: #61165e;
  border-radius: 25px;
}
/* .tau-chart__legend__item {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */
@media only screen and (max-width: 2500px) and (min-width: 768px) {
  .analysisHub-tabs tabset ul.tab-container {
    display: table !important;
  }
  .analysisHub-tabs li.nav-item.docviewer-tabs.active a {
    margin-top: 0px !important;
  }
  .analysisHub-tabs tabset ul.nav-tabs > li.docviewer-tabs {
    margin-top: 3px;
  }
}

/* @media only screen and (max-width: 1744px) and (min-width: 1642px) {
  .analysisHub-tabs .addmoreterms-list li {
    width: 100%;
    margin-right:0px !important;
  }
} */
.active a.nav-link.active a {
  color: #ffffff !important;
}
.analysisHub-tabs .limiter-popup > .popover {
  margin-top: 6px !important;
}
.add-terms-text {
  margin: 10px 0 0;
  font-family: "Source Serif Pro", serif !important;
  color: #777777;
  line-height: 20px;
}
.disabled {
  cursor: not-allowed !important;
}
app-tatlimiters .popover-content {
  width: auto !important;
}
.more-archives.wiley-secondary-text-color {
  cursor: default;
}
/* MapsGridIcon ul style modified line no 3265 */
.listview-enabled .MapsGridIcon ul {
  padding-top: 30px !important;
  margin-top: 15px !important;
  height: 130px !important;
}

/* #photo_explorer
  .archivesList
  popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
  right: auto !important;
} */

#photo_explorer
  .archivesList
  popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
  width: 100% !important;
  min-height: auto !important;
  font-size: 13px;
  max-width: 630px !important;
}
#photo_explorer
  .archivesList
  popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom
  span.popover_inner_span {
  cursor: pointer;
  display: block;
  min-width: auto;
  float: left !important;
  max-width: 100%;
  white-space: normal;
}

/* #photo_explorer
  ul.dropdown-menu.customDropdownCls
  popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
  right: auto !important;
} */
#photo_explorer .searchQry .popover-content {
  width: auto !important;
  padding: 1px !important;
}
.custom-image {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  vertical-align: middle;
}
/* google re-captcha styles */
/* .rc-anchor-checkbox-label {
    font-size: 18px !important;
}
.rc-anchor-center-container, .rc-anchor-light.rc-anchor-normal {
    width: 100% !important;
}
.rc-anchor-normal .rc-anchor-content, .recaptch-div iframe {
    width: 365px !important;
} */

.advanced-searchdiv .tooltip {
  font-size: 11.6px;
}
app-photo-explorer app-map .deletLayer > .button-action {
  right: 3.5rem !important;
}
.collcates-table-hide tbody {
  max-height: 100% !important;
}
.tau-chart__layout__header {
  display: none !important;
}
/* for mac safari */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    app-map-explorer app-map .deletLayer > a.button-action {
      right: 9px !important;
    }
    app-photo-explorer app-map .deletLayer > .button-action {
      right: 3.4rem !important;
    }
    .button-action i {
      font-size: 16px;
      color: #464646;
      margin-left: 1px;
    }
  }
}
app-flim-strip .image-wrapper .ps__rail-y {
  opacity: 0.6;
}
/* disable horizondal scrool bar in photo maps explorer */
.termfrequency-popup .ps__rail-x,
.filter-options .ps__rail-x,
.advSerach_scrollbar .ps__rail-x,
.citation-scrollbar .ps__rail-x,
#thumbnailImageWrapper2 .ps__rail-x,
.help-container .ps__rail-x {
  opacity: 0 !important;
}
.inline-block {
  display: inline-block;
}
.fontResponsive {
  font-size: 12px !important;
  line-height: 22px !important;
}
.chart-query-container-title .small {
  line-height: 20px;
}
@media only screen and (min-width: 930px) and (max-width: 1000px) {
  .borderLeftMenu.nosearchPageHeader {
    top: 15%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .analysis-tools-parent .facet-sourceDiv:nth-last-child(4) {
    margin-top: 2px;
  }
}

/*Document viewer share css*/
.shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements {
  width: auto !important;
}
/* .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-facebook {
    top: 0px;
    left: 1px;
}
.shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-twitter {
    top: 0;
    left: 48px;
}
.shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-linkedin {
    left: -137px;
    top: 47px;
}
.shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-skype {
    left: -90px;
    top: 47px;
}
.shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-pinterest_share {
    left: -44px;
    top: 47px;
}
.shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-qzone {
    left: 2px;
    top: 47px;
}
.shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-wechat {
    left: 49px;
    top: 47px;
}
.shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-compact {
    left: -137px;
    top: 94px;
} */

.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-facebook {
  top: 0px;
  left: 0px;
}

.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-twitter {
  top: 0;
  left: 48px;
}

/* .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-linkedin {
    left: -140px;
    top: 47px;
} */

.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-skype {
  left: -93px;
  top: 47px;
}

.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-pinterest_share {
  left: -46px;
  top: 47px;
}

.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-qzone {
  left: 1px;
  top: 47px;
}

.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-wechat {
  left: 48px;
  top: 47px;
}

/* .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-compact {
    left: -140px;
    top: 94px;
} */

.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-linkedin {
  left: 95px;
  top: 0px;
}

.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-compact {
  left: 95px;
  top: 47px;
}
.shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn {
  position: absolute !important;
}

.fixed-dropdown-menu {
  overflow: hidden;
  padding: 5px;
  position: relative;
  display: block;
  height: auto;
  overflow-y: auto;
  max-height: 300px;
}

.fixed-dropdown-menu a {
  padding: 3px 10px !important;
  position: relative;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.fixed-dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.fixed-dropdown-menu::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  height: 6px;
}
.fixed-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #61165e;
  border-radius: 25px;
}
.fixed-dropdown-menu .dropdown-item {
  cursor: pointer;
}
/* below commented style for ghost loader */
/* @-webkit-keyframes placeHolderShimmer {
	  0% {
	    background-position: -468px 0
	  }
	  100% {
	    background-position: 468px 0
	  }
	}

	@keyframes placeHolderShimmer {
	  0% {
	    background-position: -468px 0
	  }
	  100% {
	    background-position: 468px 0
	  }
	}

	.animated-background {
	  -webkit-animation-duration: 1s;
	  animation-duration: 1s;
	  -webkit-animation-fill-mode: forwards;
	  animation-fill-mode: forwards;
	  -webkit-animation-iteration-count: infinite;
	  animation-iteration-count: infinite;
	  -webkit-animation-name: placeHolderShimmer;
	  animation-name: placeHolderShimmer;
	  -webkit-animation-timing-function: linear;
	  animation-timing-function: linear;
	  background: #f6f7f8;
	  background: #eeeeee;
	  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
	  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	  -webkit-background-size: 800px 104px;
	  background-size: 800px 104px;
	  height: 15px;
      position: relative;
      top: 10px;
	} */
.WordClass.word-cloud-selected-term {
  background: #61165e;
  color: #fff !important;
}

.guestShare
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn {
  position: static !important;
}
.content-blocks .tooltip-inner {
  font-size: 13px !important;
  padding: 10px 10px !important;
  text-align: justify !important;
  font-family: "Open Sans", sans-serif !important;
}
@media only screen and (max-width: 1025px) {
  .content-blocks tooltip-content {
    display: none !important;
  }
}
typeahead-container ul.dropdown-menu {
  padding-top: 0px;
  border-radius: 0;
  width: 915px;
}

.suggested-term {
  margin-bottom: 0;
  padding: 6px 2px;
}
typeahead-container .dropdown-menu > .active > a,
typeahead-container .dropdown-menu > .active > a:hover,
typeahead-container .dropdown-menu > .active > a:focus {
  background-color: #61165e !important;
}
/* .page-content-right .internalShare span.spriteico.shareIcon{
    background-position: -520px -651px !important;
} */
typeahead-container li.dropdown-header {
  font-size: 14px;
  color: #61165e;
  text-transform: capitalize;
  font-weight: 600;
}
/* .listViewSwitch.gridview-enable ul>li.menu-list.dropdown  .dropdown-menu.shareWidth {
    right: -241px !important;
    top: auto !important;
    bottom: 0px !important;
    min-width:  586px !important;
   z-index: 1069;
    height: 345px;
    left: auto;
}
.listViewSwitch.listview-enabled ul>li.menu-list.dropdown  .dropdown-menu.shareWidth {
    min-width:  586px !important;
    padding: 0px !important;
    left: auto;
     z-index: 1069;
    height: 317px;
    right: 23px !important;
    top:20px;
}
@media only screen and (min-width: 1024px) {
.listViewSwitch.gridview-enable ul>li.sharelastColumn .shareDocumentClss>.dropdown-menu.shareWidth{
    right: -111px !important;
}
.listViewSwitch.gridview-enable ul>li.shareFirstColumn .shareDocumentClss>.dropdown-menu.shareWidth{
    left: -41px !important;
}
.showfullwidth-searchpage .listViewSwitch.gridview-enable ul>li.menu-list.dropdown .dropdown-menu.shareWidth {
    right: -135px !important;
}
}

@media only screen and (max-width: 1024px) {
    .listViewSwitch.gridview-enable ul>li.menu-list.dropdown  .dropdown-menu.shareWidth {
        top: auto !important;
        bottom: 0px !important;
        min-width:  586px !important;
        z-index: 1069;
        height: 345px;
        left: auto;
    }
    .listViewSwitch.gridview-enable ul>li.mob_sharelastColumn .shareDocumentClss>.dropdown-menu.shareWidth {
       left: -170px !important;
    }
    .listViewSwitch.gridview-enable ul>li.mob2_sharelastColumn .shareDocumentClss>.dropdown-menu.shareWidth {
        margin-right: 95px !important;
     }
}


.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth {
     display: block;
    right: 0 !important;
    top: 25px !important;
    min-width: 300px !important;
    z-index: 1069;
    display: block !important;
    height: 500px !important;
    left:1px !important;
}

.displayFlex {
    display: flex !important;
}
.displayNone {
    display: none !important;
}


.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth h4,
.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth p,
.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth span,
.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth a {
    font-size: 13px !important;
}

.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth ul#shareIconsWidth .addthis_inline_share_toolbox{
    right: 177px !important;
}
.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .vLine{
    margin-top:1px !important;
}

.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-skype {
    left: 143px;
    top: 0px;
}

.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth  .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-pinterest_share {
    left: -94px;
    top: 47px;
}

.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-qzone {
    left: -47px;
    top: 47px;
}

.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-wechat {
    left: 0px;
    top: 47px;
}

.mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-compact {
    left: 47px;
    top: 47px;
}

.mapView-enable.gridview-enable ul.search-iconsdiv li {
    position: relative;
}

.mapView-enable.gridview-enable .search-div-icon> ul li app-share-document.shareDocumentClss{
    position: unset !important;
}



.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth {
    display: block;
   right: 0 !important;
   top: 25px !important;
   min-width: 300px !important;
   z-index: 1069;
   display: block !important;
   height: 500px !important;
   left:auto !important;
}

.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth h4,
.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth p,
.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth span,
.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth a {
   font-size: 13px !important;
}

.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth ul#shareIconsWidth .addthis_inline_share_toolbox{
   right: 177px !important;
}
.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .vLine{
   margin-top:1px !important;
}

.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-skype {
   left: 143px;
   top: 0px;
}

.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth  .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-pinterest_share {
   left: -94px;
   top: 47px;
}

.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-qzone {
   left: -47px;
   top: 47px;
}

.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-wechat {
   left: 0px;
   top: 47px;
}

.mapView-enable.listview-enabled ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-compact {
   left: 47px;
   top: 47px;
}

.mapView-enable.listview-enabled ul.search-iconsdiv li {
   position: relative;
}



.mapView-enable.listview-enabled .PhotographsGridIcon  ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth {
    display: block;
   right: 0 !important;
   top: 25px !important;
   min-width: 310px !important;
   z-index: 1069;
   display: block !important;
   height: 500px !important;
   left:1px !important;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth h4,
.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth p,
.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth span,
.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss> .dropdown-menu.shareWidth a {
   font-size: 13px !important;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth ul#shareIconsWidth .addthis_inline_share_toolbox{
   right: 45px !important;
}
.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .vLine{
   margin-top:1px !important;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-skype {
   left: 143px;
   top: 0px;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth  .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-pinterest_share {
   left: -94px;
   top: 47px;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-qzone {
   left: -47px;
   top: 47px;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-wechat {
   left: 0px;
   top: 47px;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-compact {
   left: 47px;
   top: 47px;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul.search-iconsdiv li {
   position: relative;
}

.mapView-enable.listview-enabled .PhotographsGridIcon .search-div-icon> ul li app-share-document.shareDocumentClss{
   position: unset !important;
}

.mapView-enable.gridview-enable .MapsGridIcon  ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth ul#shareIconsWidth .addthis_inline_share_toolbox
{
right:-1px !important;
}

.mapView-enable.gridview-enable .PhotographsGridIcon  ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth ul#shareIconsWidth .addthis_inline_share_toolbox{
    right:45px !important;
    }
.mapView-enable.gridview-enable .MapsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth,
.mapView-enable.gridview-enable .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth{
min-width:310px !important;
}

.mapView-enable.listview-enabled .MapsGridIcon .shareWidth  ul {
    padding-top: 0px !important;
    margin-top: 0px !important;
    height: auto !important;
}

.mapView-enable.listview-enabled .MapsGridIcon  ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth ul#shareIconsWidth .addthis_inline_share_toolbox {
    right: -1px !important;
}
@media (min-width: 1024px) and (max-width:1700px) {
    .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth, .mapView-enable.gridview-enable .MapsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth {
       min-width: 220px !important;
       height: 640px !important;
   }
   .mapView-enable.gridview-enable .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth {
        min-width: 220px !important;
    }
    .mapView-enable.gridview-enable .PhotographsGridIcon ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth ul#shareIconsWidth .addthis_inline_share_toolbox {
        right: 1px !important;
    }
   .shareLimitedPanel .bookMarkLink, .shareAllPanel .bookMarkLink {
       word-break: break-all;
   }

   .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth ul#shareIconsWidth .addthis_inline_share_toolbox{
       right: 97px !important;
   }
   .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .vLine{
       margin-top:1px !important;
   }
   .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-linkedin {
        left: -93px;
        top: 46px;
    }
   .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-skype {
        left: -47px;
        top: 47px;
   }

   .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth  .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-pinterest_share {
        left: 0px;
        top: 47px;
   }

   .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-qzone {
        left: 47px;
        top: 47px;
   }

   .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-wechat {
        left: -93px;
        top: 93px;
   }

   .mapView-enable.gridview-enable ul>li.menu-list.dropdown .shareDocumentClss>.dropdown-menu.shareWidth .shareAllPanel .addthis_inline_share_toolbox .at-share-btn-elements>.at-share-btn.at-svc-compact {
        left: -46px;
        top: 93px;
   }

   .mapView-enable.gridview-enable ul.search-iconsdiv li {
       position: relative;
   }

   .mapView-enable.gridview-enable .search-div-icon> ul li app-share-document.shareDocumentClss{
       position: unset !important;
   }
}
.listview-enabled .MapsGridIcon .shareWidth ul {
    padding-top: 0px !important;
    height: auto !important;
    margin-top: 0 !important;
}

.listViewSwitch.listview-enabled:last-child .MapsGridIcon ul>li.menu-list.dropdown .dropdown-menu.shareWidth {
    top: -127px;
}
.listViewSwitch.listview-enabled ul>li.menu-list.dropdown.photoFirstTwoColumn .dropdown-menu.shareWidth {
    min-width: 586px !important;
    top: 20px;
    z-index: 1069;
    height: 317px;
    left: 0 !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .gridview-enable.mapView-enable .search-div-icon > ul li app-share-document.shareDocumentClss {
        position: static !important;
    }
    .gridview-enable.mapView-enable ul > li.dropdown.menu-list .shareDocumentClss > .shareWidth.dropdown-menu {
        top: 30px !important;
    }
} */

/* **************************************************************************** */

.listViewSwitch.gridview-enable
  ul
  > li.menu-list.dropdown
  .dropdown-menu.shareWidth {
  top: auto !important;
  bottom: 6px !important;
  min-width: 586px !important;
  z-index: 1069;
  height: 345px;
  left: auto;
  display: flex;
}
.listViewSwitch.listview-enabled
  ul
  > li.menu-list.dropdown
  .dropdown-menu.shareWidth {
  min-width: 586px !important;
  padding: 0px !important;
  left: auto;
  z-index: 1069;
  /* height: 317px;
   */
  height: auto !important;
  right: 23px !important;
  top: 20px;
  display: flex;
}
.listViewSwitch.gridview-enable
  ul
  > li.startWithRightSide
  .dropdown-menu.shareWidth {
  top: auto !important;
  bottom: 6px !important;
  min-width: 586px !important;
  z-index: 1069;
  height: 345px;
  right: 0 !important;
  display: flex;
}
.share-collection .dropdown-menu.shareWidth {
  min-width: 586px !important;
  padding: 0px !important;
  left: auto;
  z-index: 1069;
  height: auto !important;
  right: 20px;
  top: 20px;
  display: flex;
}
.share-collection-details .dropdown-menu.shareWidth{
  right: unset !important;
}
.listViewSwitch.listview-enabled
  .PhotographsGridIcon
  ul.PhotographsListViewIcon
  > li.startWithLeftSide
  .dropdown-menu.shareWidth {
  left: 0 !important;
}
.listViewSwitch.listview-enabled
  .PhotographsGridIcon
  ul.PhotographsListViewIcon
  > li.startWithRightSide
  .dropdown-menu.shareWidth {
  right: 0 !important;
}
.displayFlex {
  display: flex !important;
}
.displayNone {
  display: none !important;
}

.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth {
  display: block;
  right: 0 !important;
  top: 25px !important;
  min-width: 300px !important;
  z-index: 1069;
  display: block !important;
  height: 500px !important;
  left: auto !important;
}

.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  h4,
.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  p,
.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  span,
.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  a {
  font-size: 13px !important;
}

.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  ul#shareIconsWidth
  .addthis_inline_share_toolbox {
  right: 177px !important;
}
.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .vLine {
  margin-top: 1px !important;
}

.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-skype {
  left: 143px;
  top: 0px;
}

.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-pinterest_share {
  left: -94px;
  top: 47px;
}

.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-qzone {
  left: -47px;
  top: 47px;
}

.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-wechat {
  left: 0px;
  top: 47px;
}

.mapView-enable.listview-enabled
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-compact {
  left: 47px;
  top: 47px;
}

.mapView-enable.listview-enabled ul.search-iconsdiv li {
  position: relative;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth {
  display: block;
  right: 0 !important;
  top: 25px !important;
  min-width: 310px !important;
  z-index: 1069;
  display: block !important;
  height: 500px !important;
  left: 1px !important;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  h4,
.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  p,
.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  span,
.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  a {
  font-size: 13px !important;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  ul#shareIconsWidth
  .addthis_inline_share_toolbox {
  right: 45px !important;
}
.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .vLine {
  margin-top: 1px !important;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-skype {
  left: 143px;
  top: 0px;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-pinterest_share {
  left: -94px;
  top: 47px;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-qzone {
  left: -47px;
  top: 47px;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-wechat {
  left: 0px;
  top: 47px;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-compact {
  left: 47px;
  top: 47px;
}

.mapView-enable.listview-enabled .PhotographsGridIcon ul.search-iconsdiv li {
  position: relative;
}

.mapView-enable.listview-enabled
  .PhotographsGridIcon
  .search-div-icon
  > ul
  li
  app-share-document.shareDocumentClss {
  position: static !important;
}

.mapView-enable.listview-enabled .MapsGridIcon .shareWidth ul {
  padding-top: 0px !important;
  margin-top: 0px !important;
  height: auto !important;
}

.mapView-enable.listview-enabled
  .MapsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  ul#shareIconsWidth
  .addthis_inline_share_toolbox {
  right: -1px !important;
}

/* Grid and map view */
.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth {
  display: block;
  right: 0 !important;
  top: 25px !important;
  min-width: 300px !important;
  z-index: 1069;
  display: block !important;
  height: 500px !important;
  /* left: -40px !important; */
}
.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  h4,
.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  p,
.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  span,
.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  a {
  font-size: 13px !important;
}

.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  ul#shareIconsWidth
  .addthis_inline_share_toolbox {
  right: 177px !important;
}
.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .vLine {
  margin-top: 1px !important;
}

.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-skype {
  left: 143px;
  top: 0px;
}

.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-pinterest_share {
  left: -94px;
  top: 47px;
}

.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-qzone {
  left: -47px;
  top: 47px;
}

.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-wechat {
  left: 0px;
  top: 47px;
}

.mapView-enable.gridview-enable
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  .shareAllPanel
  .addthis_inline_share_toolbox
  .at-share-btn-elements
  > .at-share-btn.at-svc-compact {
  left: 47px;
  top: 47px;
}

.mapView-enable.gridview-enable ul.search-iconsdiv li {
  position: relative;
}

.mapView-enable.gridview-enable
  .search-div-icon
  > ul
  li
  app-share-document.shareDocumentClss {
  position: static !important;
}

.mapView-enable.gridview-enable
  .MapsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  ul#shareIconsWidth
  .addthis_inline_share_toolbox {
  right: -1px !important;
}

.mapView-enable.gridview-enable
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth
  ul#shareIconsWidth
  .addthis_inline_share_toolbox {
  right: 45px !important;
}
.mapView-enable.gridview-enable
  .MapsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth,
.mapView-enable.gridview-enable
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .shareDocumentClss
  > .dropdown-menu.shareWidth {
  min-width: 310px !important;
  left: 1px !important;
}

@media (min-width: 1024px) and (max-width: 1700px) {
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth,
  .mapView-enable.gridview-enable
    .MapsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    /* min-width: 220px !important; */
    height: 640px !important;
  }
  .mapView-enable.gridview-enable
    .PhotographsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    min-width: 220px !important;
  }
  .mapView-enable.gridview-enable
    .PhotographsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 1px !important;
  }
  .shareLimitedPanel .bookMarkLink,
  .shareAllPanel .bookMarkLink {
    word-break: break-all;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 97px !important;
  }
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .vLine {
    margin-top: 1px !important;
  }
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-linkedin {
    left: -93px;
    top: 46px;
  }
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-skype {
    left: -47px;
    top: 47px;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-pinterest_share {
    left: 0px;
    top: 47px;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-qzone {
    left: 47px;
    top: 47px;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-wechat {
    left: -93px;
    top: 93px;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-compact {
    left: -46px;
    top: 93px;
  }

  .mapView-enable.gridview-enable ul.search-iconsdiv li {
    position: relative;
  }

  .mapView-enable.gridview-enable
    .search-div-icon
    > ul
    li
    app-share-document.shareDocumentClss {
    position: static !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.startWithMiddleSide
    .dropdown-menu.shareWidth {
    left: -230px !important;
  }
  .listViewSwitch.listview-enabled.listview-only
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithMiddleSide
    .dropdown-menu.shareWidth {
    left: -230px !important;
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth,
  .mapView-enable.gridview-enable
    .MapsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    min-width: 220px !important;
    height: 640px !important;
  }
  .mapView-enable.gridview-enable
    .PhotographsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    min-width: 220px !important;
  }
  .mapView-enable.gridview-enable
    .PhotographsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 1px !important;
  }
  .shareLimitedPanel .bookMarkLink,
  .shareAllPanel .bookMarkLink {
    word-break: break-all;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 88px !important;
  }
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .vLine {
    margin-top: 1px !important;
  }
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-linkedin {
    left: -93px;
    top: 46px;
  }
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-skype {
    left: -47px;
    top: 47px;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-pinterest_share {
    left: 0px;
    top: 47px;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-qzone {
    left: 47px;
    top: 47px;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-wechat {
    left: -93px;
    top: 93px;
  }

  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-compact {
    left: -46px;
    top: 93px;
  }

  .mapView-enable.gridview-enable ul.search-iconsdiv li {
    position: relative;
  }

  .mapView-enable.gridview-enable
    .search-div-icon
    > ul
    li
    app-share-document.shareDocumentClss {
    position: static !important;
  }

  .mapView-enable.gridview-enable .PhotographsGridIcon ul.search-iconsdiv li {
    width: auto !important;
  }
  .mapView-enable.gridview-enable .PhotographsGridIcon ul#shareIconsWidth {
    position: relative;
    width: auto !important;
    float: left;
    padding: 0;
  }

  .listViewSwitch.gridview-enable
    ul
    > li.startWithMiddleSide
    .dropdown-menu.shareWidth {
    left: -230px !important;
  }
  .listViewSwitch.listview-enabled.listview-only
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithMiddleSide
    .dropdown-menu.shareWidth {
    left: -230px !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox,
  .listViewSwitch.gridview-enable
    ul
    > li
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 137px !important;
  }
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.startWithRightSideTab_photos
    .dropdown-menu.shareWidth {
    right: 0 !important;
  }
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.startWithLeftSideTab_photos
    .dropdown-menu.shareWidth {
    left: 0 !important;
  }

  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.startWithMiddleSideTab_photos
    .dropdown-menu.shareWidth {
    top: 20px !important;
    min-width: 586px !important;
    z-index: 1069;
    /* height: 317px;
     */
    height: 400px;
    left: -120px !important;
    display: flex !important;
  }
}

.listViewSwitch.listview-enabled:last-child
  .MapsGridIcon
  ul
  > li.menu-list.dropdown
  .dropdown-menu.shareWidth {
  top: -127px;
}

.listViewSwitch.listview-enabled .MapsGridIcon .shareWidth ul {
  padding-top: 0px !important;
  margin-top: 0px !important;
  height: auto !important;
}
@media (min-width: 767px) and (max-width: 800px) {
  .listViewSwitch.gridview-enable
    ul
    > li.startWithLeftSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.listview-enabled
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithLeftSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    left: 0;
    display: block !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.startWithRightSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.listview-enabled
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithRightSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    right: 0;
    display: block !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.startWithRightSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox,
  .listViewSwitch.gridview-enable
    ul
    > li.startWithLeftSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 144px !important;
  }
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.startWithLeftSideTab_photos
    .dropdown-menu.shareWidth,
  .listViewSwitch.listview-enabled
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithLeftSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    left: 0;
    display: block !important;
  }
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox,
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 152px !important;
  }
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.startWithRightSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    right: 0;
    display: block !important;
  }
  .mapView-enable.listview-enabled
    ul.PhotographsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .listViewSwitch.gridviewDiv {
    width: 50% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 650px) {
  .listViewSwitch.gridview-enable
    ul
    > li.menu-list.dropdown
    .dropdown-menu.shareWidth {
    right: -65px !important;
    top: 25px !important;
    min-width: 265px !important;
    z-index: 1069;
    display: block !important;
    height: 560px !important;
    font-size: 13px;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.startWithLeftSideTab
    .dropdown-menu.shareWidth {
    left: -50px !important;
  }
  .listViewSwitch.listview-enabled
    ul
    > li.menu-list.dropdown
    .dropdown-menu.shareWidth {
    right: -10px !important;
    top: 25px !important;
    min-width: 270px !important;
    width: 270px;
    z-index: 1069;
    display: block !important;
    height: 525px !important;
    font-size: 13px;
  }
  app-analysis-hub-wrapper app-tatlimiters .limitors-dropdown-child {
    margin-left: -11px !important;
  }
}

.from-badge {
  float: right;
  text-transform: capitalize;
  background: #e7e7ea;
  border-radius: 3px;
  padding: 1px 8px;
  color: #000;
  cursor: default;
}

.search-results-suggested-text {
  float: left;
  background: #fff;
  position: absolute;
  top: 49px;
  left: 264px;
  padding: 10px 12px;
  width: 915px;
  z-index: 999;
}
/* photo front back animation */
/* .openseadragon-canvas.animate-back canvas {
    -webkit-animation: rotateitback 2.5s;
    -moz-animation: rotateitback 2.5s;
    -o-animation: rotateitback 2.5s;
    animation: rotateitback 2.5s;
}
.openseadragon-canvas.animate-front canvas {
    -webkit-animation: rotateitfront 2.5s;
    -moz-animation: rotateitfront 2.5s;
    -o-animation: rotateitfront 2.5s;
    animation: rotateitfront 2.5s;
}
@-webkit-keyframes rotateitfront {
    0%   { transform: rotateY(0deg); }
  100% { transform: rotateY(180deg); }
}
@keyframes rotateitfront {
    0%   { transform: rotateY(0deg); }
    100% { transform: rotateY(180deg); }
}
@-webkit-keyframes rotateitback {
    0%   { transform: rotateY(180deg); }
  100% { transform: rotateY(0deg); }
}
@keyframes rotateitback {
    0%   { transform: rotateY(180deg); }
    100% { transform: rotateY(00deg); }
} */

.listViewSwitch.listview-enabled.listview-only:last-child
  ul
  > li.menu-list.dropdown
  .dropdown-menu.shareWidth {
  top: auto !important;
  bottom: 0 !important;
}
.listViewSwitch.listview-enabled.listview-only:last-child
  .PhotographsGridIcon
  ul
  > li.menu-list.dropdown
  .dropdown-menu.shareWidth {
  top: 20px;
  bottom: none !important;
}
/* .grecaptcha-badge {
  visibility: hidden !important;
  z-index: 1060;
} */
.listViewSwitch.listview-only
  ul.PhotographsListViewIcon
  > li.menu-list.dropdown
  .dropdown-menu.shareWidth,
.listViewSwitch.listview-enabled.listview-only:last-child
  ul.PhotographsListViewIcon
  > li.menu-list.dropdown
  .dropdown-menu.shareWidth {
  bottom: 7px !important;
  top: auto !important;
  /* left: -67px;
    min-width: 275px !important; */
}
.fontNormal {
  font-weight: normal;
}
span.mapIcon.fa.fa-map-marker.browsemap_icon.defaultColor {
  color: #61165e !important;
}
/*
Mobile View fix
*/
@media only screen and (min-width: 320px) and (max-width: 480px) {
  app-search-filter .filter-toggle-icon .search-toggle-btn {
    left: 36% !important;
  }
  .wol-help-container.show-wol-help {
    width: 100%;
    left: 0;
  }
  .display-text-contet {
    font-size: 11px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 520px) {
  .limiter-mobile-res div.mobile-btn {
    display: flex !important;
  }
  .width-15,
  wiley-searchresult-content-component .nav.toggle {
    top: 122px !important;
  }
  .csl-entry {
    overflow: unset !important;
    text-overflow: unset !important;
    -webkit-line-clamp: unset !important;
  }
}
@media only screen and (max-width: 767px) and (min-width: 480px) {
  #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom
    .popover-content
    > div {
    width: 370px !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.startWithLeftSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.listview-enabled
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithLeftSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    left: 0;
    display: block !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.startWithRightSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.listview-enabled
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithRightSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    right: 0;
    display: block !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.menu-list.dropdown
    .dropdown-menu.shareWidth {
    right: -65px !important;
    top: 25px !important;
    min-width: 265px !important;
    z-index: 1069;
    display: block !important;
    height: 560px !important;
    font-size: 13px;
  }
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  #photo_explorer
    popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom
    .popover-content
    > div {
    width: 310px !important;
  }
  #photo_explorer .mobile-btn {
    display: flex !important;
  }
  ul.nav.nav-stacked.flex-column.nav-pills {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(75vh - 76px);
  }
  #leftMenuTab li.nav-item,
  #leftMenuTab li.nav-item a.nav-link {
    /* height: 50px !important; */
    height: auto !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #61165e;
    border-radius: 25px;
  }
  #leftMenuTab .tab-content {
    width: 215px !important;
  }
  .image-wrapper section .image-container {
    width: 85px !important;
  }
  #thumbnailImageWrapper {
    max-height: 52vh !important;
  }
  .pages-title.socure-citation-linkbtn {
    font-size: 14px !important;
  }
  .doc-right .doc-fultext-btn {
    font-size: 11px !important;
  }
  ul.document-view-pagination-list li {
    margin: 0px 2px !important;
    font-size: 12px !important;
  }
  .document-view-pagination {
    margin-left: -22px !important;
  }
  #fullTextTab {
    height: 74vh;
    overflow-y: auto;
  }
  .full_text.float-right {
    position: relative;
    left: 25px;
  }
  #itemsTabset ul li.nav-item {
    width: 100%;
  }
  h4.reletaed-subject-heading.pull-left.socure-citation-linkbtn {
    font-size: 15px !important;
  }
  .download-button .dropdown-menu.width250 {
    left: -115px !important;
    top: 24px;
  }
  li.print-button.image-viewer-flip-list .dropdown-menu-position {
    top: 30px;
    left: -75px;
  }
  .listview-enabled.listViewSwitch
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 146px !important;
  }
  li.menu-list.dropdown.documentLevelShare.startWithLeftSideTab_photos
    .dropdown-menu.shareWidth {
    left: -75px !important;
  }
  .mapView-enable.gridview-enable
    ul.MonographsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    left: -50px !important;
  }
  .mapView-enable.listview-enabled
    ul.MapsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth,
  .mapView-enable.listview-enabled
    ul.MonographsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    right: -8px !important;
  }
  .mapView-enable.listview-enabled
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth,
  .mapView-enable.listview-enabled
    .PhotographsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    right: -27px !important;
    min-width: 204px !important;
    width: 204px !important;
    height: 665px !important;
  }
  .mapView-enable.gridview-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    min-width: 204px !important;
    left: -67px !important;
    height: 666px !important;
  }
  .mapView-enable.gridview-enable
    .MapsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth,
  .mapView-enable.gridview-enable
    .PhotographsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth {
    min-width: 204px !important;
    left: 0 !important;
    height: 668px !important;
  }
  .mapView-enable.gridview-enable
    .MapsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements {
    width: 179px !important;
  }
  .mapView-enable.gridview-enable
    .PhotographsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements {
    width: 133px !important;
  }
  .bookMarkLink {
    word-break: break-all;
  }
  .mapView-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-facebook {
    top: 0px !important;
    left: 177px !important;
  }
  .mapView-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-twitter {
    top: 0px !important;
    left: 224px !important;
  }
  .mapView-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-linkedin {
    left: 84px !important;
    top: 48px !important;
  }
  .mapView-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-skype {
    left: 132px !important;
    top: 48px !important;
  }
  .mapView-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-pinterest_share {
    left: 178px !important;
    top: 48px !important;
  }
  .mapView-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-qzone {
    left: 225px !important;
    top: 48px !important;
  }
  .mapView-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-wechat {
    left: 84px !important;
    top: 95px !important;
  }
  .mapView-enable
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    .shareAllPanel
    .addthis_inline_share_toolbox
    .at-share-btn-elements
    > .at-share-btn.at-svc-compact {
    left: 132px !important;
    top: 95px !important;
  }
  .mapView-enable.listview-enabled
    .MapsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox,
  .mapView-enable.listview-enabled
    .PhotographsGridIcon
    ul
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 177px !important;
  }
  .heatmap-result-leftbar .toggle-filterdivleft label {
    padding: 15px 5px 17px 5px !important;
  }
}
.rgs-content-heading {
  font-size: 18px;
  margin-top: 0;
}
@media only screen and (min-width: 375px) and (max-width: 900px) {
  popover-container.popover-right.bs-popover-right.right {
    width: 147px;
    /* left: -12px !important; */
    /* top: 85px !important; */
  }
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .gridviewDiv {
    width: 100%;
  }

  wiley-search-result {
    width: 100% !important;
  }
  wiley-search-result .heading {
    font-size: 13px !important;
  }
  popover-container.popover-right.bs-popover-right.right {
    width: 147px;
    /* left: -12px !important; */
    /* top: 85px !important; */
  }
  /* wiley-search-result
    .panel-group
    > .panel:first-child
    .accordian-inner:first-child
    .popover {
    top: 85px !important;
  } */
}
@media only screen and (min-width: 320px) and (max-width: 630px) {
  .spriteico_mob {
    float: left;
    background: url(/assets/images/icons/sprite_img-3.png) no-repeat;
    display: block;
    width: 28px;
    height: 28px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 540px) {
  .coverflow-thumb-image {
    width: auto !important;
  }
  .coverflow-title-div {
    float: unset !important;
    width: calc(100% - 0px) !important;
  }
  .document-image-titlelbl {
    white-space: normal !important;
  }
  .coverflow-images-div {
    float: unset !important;
  }
  .coverflow-innerdiv {
    display: inline-flex !important;
  }
  .document-image-citationlbl {
    white-space: normal !important;
  }
  #illustrationTree .node-content-wrapper .title {
    white-space: normal;
  }
  .itemsIcon-tabs #itemsTabset .tab-content {
    left: 10px !important;
    position: relative;
  }
  .notesIcon-tabs #itemsTabset .tab-content {
    margin-left: -10px;
  }
}
@media only screen and (min-width: 540px) and (max-width: 768px) {
  .document-image-titlelbl,
  .document-image-citationlbl {
    white-space: normal !important;
  }
  .document-image-citationlbl {
    white-space: normal !important;
  }
  .coverflow-innerdiv {
    vertical-align: top !important;
  }
}
.column-content-list h4 {
  margin-bottom: 5px;
  margin-top: 0;
}
@media only screen and (max-width: 320px) {
  .heatmap-result-leftbar .toggle-filterdivleft label {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .mapView-enable.listview-enabled .Photographsclass .searchresult-imgdiv {
    width: 100% !important;
  }
}
@media (min-width: 799px) and (max-width: 1020px) {
  .listViewSwitch.gridview-enable
    ul
    > li.startWithLeftSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.listview-enabled
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithLeftSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    left: 0;
    display: block !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.startWithRightSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.listview-enabled
    .PhotographsGridIcon.illustration_list
    ul
    > li.startWithRightSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    right: 0;
    display: block !important;
  }
  .listViewSwitch.gridview-enable
    ul
    > li.startWithRightSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox,
  .listViewSwitch.gridview-enable
    ul
    > li.startWithLeftSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 144px !important;
  }
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.startWithLeftSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    left: 0;
    display: block !important;
  }
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox,
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 152px !important;
  }
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.startWithRightSideTab_photos
    .dropdown-menu.shareWidth {
    top: 25px !important;
    min-width: 275px !important;
    z-index: 1069;
    height: 595px;
    right: 0;
    display: block !important;
  }
  .mapView-enable.listview-enabled
    ul.PhotographsListViewIcon
    > li.menu-list.dropdown
    .shareDocumentClss
    > .dropdown-menu.shareWidth
    ul#shareIconsWidth
    .addthis_inline_share_toolbox {
    right: 45px !important;
  }
  .listViewSwitch.gridview-enable
    ul.MapsListViewIcon
    > li.startWithLeftSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.gridview-enable
    ul.MapsListViewIcon
    > li.startWithRightSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.gridview-enable
    ul.PhotographsListViewIcon
    > li.startWithLeftSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth,
  .listViewSwitch.gridview-enable
    ul.PhotographsListViewIcon
    > li.startWithRightSideTab
    .shareDocumentClss
    .dropdown-menu.shareWidth {
    top: auto !important;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1024px) {
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.menu-list.dropdown
    .dropdown-menu.shareWidth {
    min-width: 265px !important;
    width: 265px !important;
  }
  /* .listViewSwitch.listview-only ul.PhotographsListViewIcon>li.startWithRightSideTab_photos .dropdown-menu.shareWidth, .listViewSwitch.listview-enabled.listview-only:last-child ul.PhotographsListViewIcon>li.startWithRightSideTab_photos .dropdown-menu.shareWidth{
        left: -145px !important;
    } */
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .listViewSwitch.listview-enabled
    ul.PhotographsListViewIcon
    > li.menu-list.dropdown
    .dropdown-menu.shareWidth {
    /* left: -60px !important; */
    top: 25px !important;
    min-width: 270px !important;
    width: 270px;
    z-index: 1069;
    display: block !important;
    height: 525px !important;
    font-size: 13px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .search-results-parent-div.coverflowstyle.dynamic-search-results-parent-div {
    z-index: 1070 !important;
  }
}
/* .lastPeekViewIcon popover-container.popover-right.bs-popover-right.right {
  left: -80px !important;
} */
@media only screen and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
  ul.nav.nav-stacked.flex-column.nav-pills {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: calc(75vh - 52px) !important;
  }
  span.toggle-icon {
    bottom: -50px !important;
    background: #61165e;
  }
  .leftmenu-slimstrip {
    background: #61165e;
  }
  p.tabicon-title {
    width: 50px;
    white-space: normal !important;
  }
  app-flim-strip .image-viewer-parent .image-wrapper {
    min-height: 130px !important;
    max-height: 40vh !important;
  }
  #fullTextTab {
    height: 74vh;
    overflow: auto;
  }
  #itemTab1 .manuscriptTree2-tree-scroll,
  #itemTab2 .manuscriptTree2-tree-scroll {
    min-height: 45vh !important;
    max-height: 45vh !important;
    overflow: auto;
  }
  #itemsTabset ul li.nav-item a {
    font-size: 14px !important;
  }
  .document-notes-tab {
    max-height: 63vh !important;
  }
}
@media only screen and (min-width: 360px) and (max-width: 640px) and (orientation: landscape) {
  ul.nav.nav-stacked.flex-column.nav-pills {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: calc(75vh - 52px) !important;
  }
  span.toggle-icon {
    bottom: -50px !important;
    background: #61165e;
  }
  .leftmenu-slimstrip {
    background: #61165e;
  }
  p.tabicon-title {
    width: 50px;
    white-space: normal !important;
  }
  app-flim-strip .image-viewer-parent .image-wrapper {
    min-height: 130px !important;
    max-height: 40vh !important;
  }
  #fullTextTab {
    height: 74vh;
    overflow: auto;
  }
  #itemTab1 .manuscriptTree2-tree-scroll,
  #itemTab2 .manuscriptTree2-tree-scroll {
    min-height: 45vh !important;
    max-height: 45vh !important;
    overflow: auto;
  }
  #itemsTabset ul li.nav-item a {
    font-size: 14px !important;
  }
  .document-notes-tab {
    max-height: 63vh !important;
  }
  .fulltext-dowunload-btn {
    max-width: 100%;
    white-space: normal;
  }
}
@media only screen and (min-width: 460px) and (max-width: 768px) and (orientation: landscape) {
  ul.nav.nav-stacked.flex-column.nav-pills {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: calc(75vh - 52px) !important;
  }
  span.toggle-icon {
    bottom: -50px !important;
    background: #61165e;
  }
  .leftmenu-slimstrip {
    background: #61165e;
  }
  p.tabicon-title {
    width: 50px;
    white-space: normal !important;
  }
  app-flim-strip .image-viewer-parent .image-wrapper {
    min-height: 130px !important;
    max-height: 53vh !important;
  }
  #fullTextTab {
    height: 74vh;
    overflow: auto;
  }
  #itemTab1 .manuscriptTree2-tree-scroll,
  #itemTab2 .manuscriptTree2-tree-scroll {
    min-height: 45vh !important;
    max-height: 53vh !important;
    overflow: auto;
  }
  #itemsTabset ul li.nav-item a {
    font-size: 14px !important;
  }
  .document-notes-tab {
    max-height: 63vh !important;
  }
  .full_text.float-right .fulltext-dowunload-btn {
    max-width: 100%;
    white-space: normal;
  }
}
@media only screen and (min-width: 603px) and (max-width: 966px) and (orientation: landscape) {
  .full_text.float-right .fulltext-dowunload-btn {
    max-width: 100%;
    white-space: normal;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .full_text.float-right .fulltext-dowunload-btn {
    max-width: 100%;
    white-space: normal;
  }
}
/* wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner.mapView-enable:nth-of-type(1)
  .popover {
  top: 1px !important;
} */
.welcome-training-message {
  font-size: 14px;
  text-align: justify;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 0;
}
.welcome-training-message p {
  margin-bottom: 0;
}
.welcome-training-message a {
  color: #61165e;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}
.welcome-training-message a:hover,
.welcome-training-message a:focus {
  text-decoration: underline !important;
  color: #61165e !important;
}
.gridview-enable app-share-document .dropdown-menu.shareWidth,
.listview-only app-share-document .dropdown-menu.shareWidth,
.listview-enabled.mapView-enable app-share-document .dropdown-menu.shareWidth {
  cursor: default !important;
}
.training-hub-link {
  border: 1px solid;
  border-radius: 0 !important;
  margin-left: 10px !important;
}
.border_code {
  display: inline-block;
  width: 100%;
  border-bottom: 3px solid;
  margin-bottom: 0;
}
.modal-dialog.modal-sm.large.photo-inset-popup {
  top: 30% !important;
}
.photo-inset-popup .photoexplorer-captions.disclaimer-div {
  overflow: hidden;
}
.premariden-class .ps {
  max-height: 500px;
  min-height: 300px;
}
.temgroups-searchresult-imgdiv
  popover-container.popover.in.popover-left.bs-popover-left.left {
  display: block !important;
  z-index: 999;
  /* right: -250px !important; */
  /* left: auto !important; */
  /* top: -55px !important; */
}
.modal-dialog.awsgray.modal-lg {
  width: 700px !important;
}
.openseadragon-canvas:focus {
  outline: none;
}

.dropdown-menu.collection.shareWidth.displayFlex {
  min-width: 586px !important;
  padding: 0px !important;
  left: 0;
  z-index: 1069;
  height: 317px;
  right: 0 !important;
  top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dropdown-menu.collection.shareWidth.displayFlex p {
  line-height: 20px;
}

@media (min-width: 1270px) and (max-width: 1400px) {
  .slim-strip-container {
    height: 77.5vh;
  }
  ul.nav.nav-stacked.flex-column.nav-pills {
    height: 78vh !important;
  }
  span.toggle-icon {
    bottom: 0;
  }
  .docu-viewer-div {
    height: 77.5vh;
  }
}
.pointernone {
  pointer-events: none !important;
  opacity: 0.8;
}
.cursornotAllowed {
  cursor: not-allowed !important;
}
span.share-txt {
  color: #61165e;
  font-weight: 600;
  margin-left: 8px;
}
.map-group-icons
  popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
  /* left: -157px !important; */
  /* top: 17px !important; */
  width: 155px;
}
/* .map-group-icons.full-wdth-style
  popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
  left: -160px !important;
  top: 17px !important;
} */
.map-group-icons.browsemaps-map-gridview
  popover-container.popover.in.popover-bottom.bs-popover-bottom.bottom {
  /* left: auto !important; */
  /* right: 28px !important; */
  width: 200px !important;
  /* top: -25px !important; */
}
wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner.gridview-enable:nth-of-type(1)
  .map-group-icons.browsemaps-map-gridview
  .popover,
wiley-search-result
  .search-result-float-menu-content
  .panel-group
  > .panel:nth-of-type(1)
  .accordian-inner.gridview-enable:nth-of-type(2)
  .map-group-icons.browsemaps-map-gridview
  .popover {
  /* top: -28px !important; */
  width: 155px !important;
}
.filter-options .filter-header {
  cursor: auto !important;
}

.listview-enabled.search-iconsdiv li {
  width: auto !important;
}
/* .popover-content {
    padding: 9px 14px;
    max-width: max-content;
    width: max-content;
} */
.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 5px;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
  border-color: #35465c;
}

.resp-sharing-button--tumblr:hover,
.resp-sharing-button--tumblr:active {
  background-color: #222d3c;
  border-color: #222d3c;
}

.resp-sharing-button--email {
  background-color: #3879ee;
  border-color: #3879ee;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #0d5be8;
  border-color: #0d5be8;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
}

.resp-sharing-button--pinterest:hover,
.resp-sharing-button--pinterest:active {
  background-color: #8c0615;
  border-color: #8c0615;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
}

.resp-sharing-button--linkedin:hover,
.resp-sharing-button--linkedin:active {
  background-color: #046293;
  border-color: #046293;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
  border-color: #5f99cf;
}

.resp-sharing-button--reddit:hover,
.resp-sharing-button--reddit:active {
  background-color: #3a80c1;
  border-color: #3a80c1;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
  border-color: #1a7576;
}

.resp-sharing-button--xing:hover .resp-sharing-button--xing:active {
  background-color: #114c4c;
  border-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
  border-color: #25d366;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1da851;
  border-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
  border-color: #ff6600;
}

.resp-sharing-button--hackernews:hover .resp-sharing-button--hackernews:active {
  background-color: #fb6200;
  border-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
  border-color: #507299;
}

.resp-sharing-button--vk:hover .resp-sharing-button--vk:active {
  background-color: #43648c;
  border-color: #43648c;
}

.resp-sharing-button--telegram {
  background-color: #54a9eb;
}

.resp-sharing-button--telegram:hover {
  background-color: #4b97d1;
}
.copyBtn {
  cursor: pointer;
  background: #61165e;
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
.copyIcon {
  fill: #fff;
  stroke: none;
  margin: 0;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  color: #fff;
}
.mailBtns {
  cursor: pointer;
  background: #848484 !important;
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.container {
  max-width: 90% !important;
  background-color: #ffffff;
  padding: 0 !important;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.3);
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.dropdown-toggle::after{
  display: none;
}
a {
  text-decoration: none !important;
}
ul.docsShare > li.menu-list.dropdown .dropdown-menu.shareWidth {
  min-width: 586px !important;
  padding: 0px !important;
  left: auto;
  z-index: 1069;
  height: auto !important;
  right: 23px !important;
  top: 20px;
}
.fa-asterisk {
  color: red;
  font-size: 10px;
}
.btn-xs
{
    padding: 1px 5px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
/* tooltip */
.tooltip-inner {
  background-color: #fff !important;
  margin-bottom: 6px;
  font-weight: 700;
  color: #61165e;
  border-color: #ccc;
  border-style: solid;
  border-width: 0.5px;
  border-bottom-color: #ffffff !important;
}
.tooltip.top .tooltip-arrow:before,
.tooltip.top .tooltip-arrow {
  border-top-color: #fff !important;
}
.tooltip.bottom .tooltip-arrow:before,
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fff !important;
}
.tooltip.left .tooltip-arrow:before,
.tooltip.left .tooltip-arrow {
  border-left-color: #fff !important;
}
.tooltip.right .tooltip-arrow:before,
.tooltip.right .tooltip-arrow {
  border-right-color: #fff !important;
}
/* multiselect */
.multiselect-item-checkbox input[type=checkbox] + div:before {
  border: 2px solid #61165e !important;
  color: #61165e !important;
 }
.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  background: #61165e !important;
 }
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid #61165e !important;
  background: #61165E !important;
 }
 .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span {
  font-size: 13px !important;
 }
 .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  border-color: #61165E !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(45deg) !important;
  -webkit-transform: rotate(45deg) !important;
  line-height: unset !important;
  width: unset !important;
  height: unset !important
 }
 .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  line-height: unset !important;
  width: unset !important;
  height: unset !important
 }
 .multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret {
  top: 8px !important;
 }

 .dropdown-list ul {
  overflow-y: scroll !important;
  position: relative;
 }
.dropdown-list ul::-webkit-scrollbar {
  width: 6px !important;
}
.dropdown-list ul::-webkit-scrollbar-thumb {
  background: #61165e !important;
  border-radius: 25px !important;
}
.dropdown-list .filter-textbox {
  padding: 5px 10px !important; 
}
.btn-default {
  color: #333;
    border-color: #adadad;
}
.btn-default:hover {
  color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}